import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faTrashCan,
  faChartPie,
  faRotateRight,
  faArrowUpFromBracket,
  faMagnifyingGlass,
  faClock,
  faFileArrowDown,
  faBuilding,
  faCalendar,
  faCircleInfo
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faTrashCan,
  faChartPie,
  faRotateRight,
  faArrowUpFromBracket,
  faMagnifyingGlass,
  faClock,
  faFileArrowDown,
  faBuilding,
  faCalendar,
  faCircleInfo
)

export default FontAwesomeIcon
