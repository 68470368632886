<template>
  <DialogsWrapper v-if="!loading"/>
  <Loading v-model:active="loading" loader="dots"/>
  <div>
    <div v-if="testViewData" class="container mt-5 bg-white shadow p-3">
      <h2 class="p-3 text-primary">{{ $t('candidates.tests.disc.profileSystem') }}</h2>
      <!-- Instructions -->
      <div class="p-3">
        <div>
          {{ testViewData.instruction }}
        </div>
      </div>
      <!-- Warnings -->
      <div v-if="warnings.blankOptions || warnings.sameOption" class="pt-3 ps-3">
        <p v-if="warnings.blankOptions" class="text-danger mb-0 py-1 fw-500">{{ $t('candidates.tests.disc.blankOptionsWarning') }}</p>
        <p v-if="warnings.sameOption" class="text-danger mb-0 py-1 fw-500">{{ $t('candidates.tests.disc.sameOptionWarning') }}</p>
      </div>
      <hr>
      <!-- Questions -->
      <div class="p-3 group-width">
        <div v-for="(group, gIndex) in testViewData.questions" :key="gIndex">
          <DiscGroup
            v-if="currentGroup === gIndex + 1"
            :mostValue="answersForm.most[`g${gIndex + 1}`]"
            :leastValue="answersForm.least[`g${gIndex + 1}`]"
            :group="group"
            :gIndex="gIndex"
            :currentGroup="currentGroup"
            :maxGroups="maxGroups"
            @updateAnswersFormMost="updateAnswersForm"
            @updateAnswersFormLeast="updateAnswersForm"
          />
        </div>
        <!-- End -->
        <div class="py-3 buttons-container">
          <button v-if="currentGroup !== 1" @click="nextGroup('back')" class="btn white-general-btn my-1 mx-2">{{ $t('candidates.tests.previous') }}</button>
          <button v-if="currentGroup < maxGroups" @click="nextGroup('next')" class="btn white-general-btn my-1 mx-2">{{ $t('candidates.tests.next') }}</button>
          <button v-if="currentGroup === maxGroups" @click="sendAnswers(testAnswer.id, testAnswer.test_request_id)" class="btn general-btn my-1 mx-2">{{ $t('candidates.tests.sendAnswers') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useCandidateTestsStore } from '@/stores/candidateTests.store'
import DiscGroup from '@/components/tests/DiscGroup.vue'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import Loading from 'vue-loading-overlay'
import { useNavigationGuard } from '@/use/useNavigationGuard'
import 'vue-loading-overlay/dist/vue-loading.css'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { updateAnswer, toggleTestStatus, fetchTestData } = useCandidateTestsStore()
const { testViewData, testAnswer, loading, testInProgress } = storeToRefs(useCandidateTestsStore())
const route = useRoute()

/*
  Get test questions form and create empty answer.
*/

onBeforeMount(() => {
  fetchTestData(+route.params.id)
})

const warnings = reactive({
  sameOption: false,
  blankOptions: false
})
const maxGroups = 28
const currentGroup = ref(1)
const answersForm = reactive(
  {
    most: {
      g1: 0,
      g2: 0,
      g3: 0,
      g4: 0,
      g5: 0,
      g6: 0,
      g7: 0,
      g8: 0,
      g9: 0,
      g10: 0,
      g11: 0,
      g12: 0,
      g13: 0,
      g14: 0,
      g15: 0,
      g16: 0,
      g17: 0,
      g18: 0,
      g19: 0,
      g20: 0,
      g21: 0,
      g22: 0,
      g23: 0,
      g24: 0,
      g25: 0,
      g26: 0,
      g27: 0,
      g28: 0
    },
    least: {
      g1: 0,
      g2: 0,
      g3: 0,
      g4: 0,
      g5: 0,
      g6: 0,
      g7: 0,
      g8: 0,
      g9: 0,
      g10: 0,
      g11: 0,
      g12: 0,
      g13: 0,
      g14: 0,
      g15: 0,
      g16: 0,
      g17: 0,
      g18: 0,
      g19: 0,
      g20: 0,
      g21: 0,
      g22: 0,
      g23: 0,
      g24: 0,
      g25: 0,
      g26: 0,
      g27: 0,
      g28: 0
    }
  }
)

const confirmSendAnswersDialog = createConfirmDialog(ConfirmationModal)

/*
  Navigation guard
*/

useNavigationGuard(testInProgress, () => {
  const params = {
    test_answer: {
      answer: answersForm,
      test_request_id: testAnswer.value?.test_request_id
    }
  }
  toggleTestStatus(false)
  updateAnswer(testAnswer.value?.id, params, true)
})

/*
  Test in progress
*/

onMounted(() => {
  toggleTestStatus(true)
})

/*
  Questions logic
*/

const updateAnswersForm = (type, groupIndex, value) => {
  answersForm[type][`g${groupIndex}`] = value
}

const nextGroup = (direction) => {
  switch (direction) {
    case 'next':
      if (validateGroupInputs(currentGroup.value)) {
        currentGroup.value += 1
      }
      break
    case 'back':
      currentGroup.value -= 1
      break
  }
}

const validateGroupInputs = (groupIndex) => {
  const mostOption = answersForm.most[`g${groupIndex}`]
  const leastOption = answersForm.least[`g${groupIndex}`]

  // Validate same options
  if (mostOption === leastOption) {
    warnings.sameOption = true
  } else {
    warnings.sameOption = false
  }
  // Validate blank options
  if (mostOption === 0 || leastOption === 0) {
    warnings.blankOptions = true
  } else {
    warnings.blankOptions = false
  }

  const notValid = warnings.sameOption || warnings.blankOptions
  return !notValid
}

const validateAllInputs = () => {
  let valid = true
  for (let group = 1; group <= maxGroups; group++) {
    const validGroup = validateGroupInputs(group)
    valid = valid && validGroup
  }
  return valid
}

/*
  Send answers
*/

const sendAnswers = async (testAnswerId, testRequestId) => {
  const params = {
    test_answer: {
      answer: answersForm,
      test_request_id: testRequestId
    }
  }
  if (validateAllInputs()) {
    // Open confirmation modal
    const confirmation = await confirmSendAnswersDialog.reveal({
      icon: 'circle-info',
      title: t('candidates.tests.aboutToSendAnswers'),
      text: t('confirmAction'),
      confirmBtnText: t('candidates.tests.sendAnswers'),
      cancelBtnText: t('candidates.tests.backToTest')
    })
    // On confirm, change test status and update answer
    if (!confirmation.isCanceled) {
      toggleTestStatus(false)
      updateAnswer(testAnswerId, params, true)
    }
  }
}

</script>

<style scoped>
  .group-width {
    width: 50%;
  }
  .buttons-container{
    text-align: end;
  }

  @media (max-width: 1199.98px) {
    .group-width {
      width: 100%;
    }
    .buttons-container{
    text-align: center;
    }
  }
</style>
