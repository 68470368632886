import { onMounted, onBeforeUnmount } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { createConfirmDialog } from 'vuejs-confirm-dialog'

import i18n from '../i18n'

const { t } = i18n().global

export function useNavigationGuard (testInProgress, leavePageCallback) {
  const warningMessage = t('candidates.tests.confirmAbandonTest')
  // Known URLs on which the navigation guard should not be triggered
  const urlExceptions = ['/candidates/finished']

  const alertRouteLeave = () => {
    const answer = window.confirm(warningMessage)
    // Cancel the navigation and stay on the same page
    if (answer) {
      createConfirmDialog().close()
    } else {
      return false
    }
  }

  const alertWindowUnload = (event) => {
    // This message will only be shown on explorer
    event.returnValue = warningMessage
    return warningMessage
  }

  onBeforeRouteLeave((to, _from) => {
    // Check if user is navigating to an 'exception' URL or if test is not in progres. Otherwise, prompt them with an alert.
    return urlExceptions.includes(to.path) || !testInProgress.value || alertRouteLeave()
  })

  onMounted(() => {
    window.onbeforeunload = alertWindowUnload
    window.onunload = leavePageCallback
  })

  onBeforeUnmount(() => {
    window.onbeforeunload = undefined
    window.onunload = undefined
    if (testInProgress.value) {
      leavePageCallback()
    }
  })
}
