<template>
  <div class="container">
    <br />
    <br />
    <div class="row-w">
      <br />
      <br />
      <div class="col-lg-12 text-center">
        <img src="/files/404Image.png"/>
        <div class="px-2">
          <h4>{{ $t('sorryAnErrorHasOcurred') }}</h4>
          <p>{{ $t('pageNotFound') }}</p>
        </div>
        <br />
        <RouterLink to="/" class="btn general-btn">{{ $t('backToHome') }}</RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
  .row-w {
    margin: auto;
  }
  img {
    width: 80%;
  }

  h4 {
    color: $polux-blue;
  }

  @media (max-width: 767.98px) {
    .row-w {
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 1399.98px)  {
    .row-w {
      width: 50%;
    }
  }

  @media (min-width: 1400px) {
    .row-w {
      width: 25%;
    }
  }
</style>
