import { fetchWrapper } from '@/helpers/fetch-wrapper'

const addCandidate = (data) => {
  const url = `${process.env.VUE_APP_API_BASE}/analysts/company_candidates`
  const params = {
    candidate_attributes: {
      email: data.email,
      name: data.name,
      lastname: data.lastname
    }
  }
  return fetchWrapper.post(url, params)
}

const sendRecoverAccountEmail = (candidateEmail, userType) => {
  const url = `${process.env.VUE_APP_API_BASE}/${userType}/mails/recover_accounts`
  const params = {
    user: {
      email: candidateEmail
    }
  }
  return fetchWrapper.post(url, params)
}

// In Batch
const batchAddCandidates = (file) => {
  const url = `${process.env.VUE_APP_API_BASE}/analysts/batch/company_candidates`
  const formData = new FormData()
  formData.append('file', file, file.name)
  const params = {
    file: file
  }
  return fetchWrapper.post(url, params)
}

export { addCandidate, batchAddCandidates, sendRecoverAccountEmail }
