<template>
  <div class="container px-0">
    <slot name="header"></slot>
    <div class="row">
      <div v-if="props.questionNumber" class="col-sm-1 mb-2">
        <b class="badge bg-primary">{{ props.questionNumber }}</b>
      </div>
      <div class="statement-box col-sm-10 fw-bold">
        {{ question.statement }}
      </div>
      <div class="row">
        <div :id="`checkbox-container-form-${props.questionNumber}`" class="col-sm-12 offset-sm-1 mt-2">
          <div v-for="(alternative, gIndex) in props.alternatives" :key="gIndex">
            <div v-if="props.isExample" class="form-check my-1">
              <label class="ms-2 form-check-label">{{ alternative.text }}
                <input
                  type="checkbox"
                  v-model="checkedAnswers"
                  :id="`question-${question.id}-${alternative.value}`"
                  class="form-check-input">
              </label>
            </div>
            <div v-else class="form-check">
              <label class="ms-2 form-check-label">{{ alternative.text }}
                <input
                  type="checkbox"
                  v-model="checkedAnswers"
                  :value="alternative.value"
                  :id="`question-${question.id}-${alternative.value}`"
                  class="form-check-input">
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue'

const props = defineProps({
  question: Object,
  questionNumber: Number,
  alternatives: Array,
  answersForm: Object,
  maxExpectedAnswers: Number,
  isExample: Boolean
})

const checkedAnswers = ref([])
const emit = defineEmits(['updateAnswersForm'])

watch(() => checkedAnswers.value, (newValue) => {
  const answersArray = JSON.parse(JSON.stringify(newValue))

  if (props.maxExpectedAnswers) {
    // Disable unchecked inputs if checked inputs matches maxExpectedAnswers condition
    const checkboxContainerForm = document.getElementById(`checkbox-container-form-${props.questionNumber}`)
    const uncheckedInputs = checkboxContainerForm.querySelectorAll('input[type="checkbox"]:not(:checked)')

    uncheckedInputs.forEach(checkbox => {
      checkbox.disabled = answersArray.length === props.maxExpectedAnswers
    })
  }

  emit('updateAnswersForm', props.question.id, answersArray)
})

</script>

<style scoped lang="scss">
  .statement-box {
    white-space:pre-wrap;
  }
</style>
