import { createI18n } from 'vue-i18n'
import { nextTick } from 'vue'

import esLocale from '../locales/es'
import ptLocale from '../locales/pt'

let i18n

export const SUPPORT_LOCALES = ['pt', 'es']

export function getCurrentLocale () {
  return i18n.global.locale.value
}

export function setI18nLanguage (locale) {
  loadLocaleMessages(locale)

  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }

  document.querySelector('html').setAttribute('lang', locale)
  localStorage.setItem('lang', locale)
}

export async function loadLocaleMessages (locale) {
  // load locale messages with dynamic import
  const messages = await import(`../locales/${locale}`)

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}

export default function setupI18n () {
  if (!i18n) {
    const locale = localStorage.getItem('lang') || 'es'

    i18n = createI18n({
      globalInjection: true,
      legacy: false,
      locale: locale,
      fallbackLocale: 'es',
      messages: {
        es: esLocale,
        pt: ptLocale
      }
    })

    setI18nLanguage(locale)
  }
  return i18n
}
