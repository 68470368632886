export default {
  genericError: 'Ha ocurrido un error, por favor intenta de nuevo.',
  dataUpdatedSuccessfully: 'Los datos han sido actualizados satisfactoriamente.',
  passwordUpdatedSuccessfully: 'La contraseña ha sido actualizada satisfactoriamente.',
  sorryAnErrorHasOcurred: 'Lo sentimos, ha ocurrido un error',
  pageNotFound: 'No pudimos encontrar la página que estás buscando te invitamos a volver al inicio.',
  backToHome: 'Volver al inicio',
  signOut: 'Cerrar sesión',
  back: 'Volver',
  noSearchResults: 'No hay resultados para tu búsqueda',
  requiredField: 'Campo requrido',
  dragAndDropToUploadFile: 'Click o arrastra tu archivo en esta área para subirlo',
  maxFileSize: 'Máximo tamaño 2MB.',
  excelFormat: 'Formato Excel.',
  continue: 'Continuar',
  confirmAction: '¿Estás seguro de que deseas realizar esta acción?',

  login: {
    email: 'Correo electrónico',
    password: 'Contraseña',
    signIn: 'Ingresar',
    areYouClient: '¿Eres un cliente?',
    areYouCandidate: '¿Eres un candidato?',
    signInHere: 'Inicia sesión aquí',
    forgotPassword: '¿Olvidaste tu contraseña?',
    sendRecoveryEmail: 'Enviar correo de recuperación',
    invalidCredentials: 'Credenciales inválidas',
    recoveryEmailSentSuccessfully: 'Email de recuperación enviado exitosamente',
    nonExistentEmail: 'Email inexistente'
  },

  account: {
    title: 'Cuenta',
    accountData: 'Datos de la cuenta',
    firstName: 'Nombre',
    lastName: 'Apellido',
    language: 'Idioma',
    updateData: 'Actualizar datos',
    changePassword: 'Cambiar contraseña',
    changeOrUpdatePassword: 'Cambiar o actualizar contraseña',
    currentPassword: 'Contraseña actual',
    newPassword: 'Contraseña nueva',
    passwordConfirmation: 'Repetir contraseña nueva',
    passwordsDontMatch: 'Las contraseñas no coinciden'
  },

  languages: {
    es: 'Español',
    pt: 'Portugués'
  }
}
