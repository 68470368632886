<template>
  <div class="container-fluid">
    <!-- Add candidates -->
    <CandidatesForm formAction="add" :showAccordion="candidates.length === 0" />
    <!-- Candidates -->
    <Loading v-model:active="loading" loader="dots" />
    <div v-if="!loading">
      <CandidatesList :originalObjectsArray="candidates" />
    </div>
  </div>
</template>

<script setup>
import CandidatesForm from '@/components/CandidatesForm.vue'
import CandidatesList from '@/components/CandidatesList.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import { storeToRefs } from 'pinia'
import { useAnalystTestsStore } from '@/stores/analystTests.store'

const { candidates, loading } = storeToRefs(useAnalystTestsStore())
const { fetchCandidates } = useAnalystTestsStore()

fetchCandidates()
</script>
