<template>
  <!-- Requests -->
  <Loading v-model:active="loading" loader="dots"/>
  <div v-if="!loading">
    <RequestsList
      fromView="requests"
      :title="$t('analysts.requests.requestsMade')"
      :originalObjectsArray="testRequests"
    />
  </div>
</template>

<script setup>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import RequestsList from '@/components/RequestsList.vue'

import { storeToRefs } from 'pinia'
import { useAnalystTestsStore } from '@/stores/analystTests.store'

const { fetchAllTestRequests } = useAnalystTestsStore()
const { testRequests, loading } = storeToRefs(useAnalystTestsStore())

// Get requests
fetchAllTestRequests()
</script>
