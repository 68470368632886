import { ref, computed, onMounted, watch } from 'vue'
import { objectsFilter } from '@/helpers/attributes-text-filter'

export function usePaginationAndSearch (props, searchInput, filterableAttributes, nEntries, nested = false, nestedAttr = '') {
  /*
  Search
  */

  const filteredObjects = computed(
    () => objectsFilter(props.originalObjectsArray, filterableAttributes, searchInput.value, nested, nestedAttr)
  )

  /*
    Pagination
  */

  const paginatedObjects = ref([])
  const currentPage = ref(1)
  const paginationKey = ref(0)
  const nPages = computed(
    () => Math.ceil(filteredObjects.value.length / nEntries)
  )

  const forceRerender = () => {
    paginationKey.value += 1
  }

  const onClickHandler = (page = 1) => {
    currentPage.value = page
    paginatedObjects.value = filteredObjects.value.slice(0 + nEntries * (page - 1), nEntries * page)
  }

  onMounted(onClickHandler)

  /*
    Search and pagination
  */

  watch(() => filteredObjects.value, () => {
    currentPage.value = 1
    onClickHandler()
    forceRerender()
  })

  return {
    filteredObjects,
    paginatedObjects,
    currentPage,
    paginationKey,
    nPages,
    onClickHandler
  }
}
