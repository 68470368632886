<template>
  <Loading v-model:active="loadingPage" loader="dots" />
  <div v-if="!loadingPage">
    <div class="shadow p-3 mx-auto my-5 rounded-8 w-75 bg-white">
      <h5 class="p-3 text-primary">{{ $t('analysts.requests.inviteCandidateToTest', { candidateInfo: candidateInfo }) }}</h5>
      <div class="p-3">
        <div class="row row-cols-auto justify-content-center">
          <div class="col my-3 px-0 me-4" v-for="test in tests" :key="test.id">
            <TestCard :test="test">
              <template v-slot:actions>
                <div class="card-footer bg-transparent border-0 py-3">
                  <button @click="inviteCandidateToTest(test.id)" class="btn general-btn">
                    {{ $t('analysts.tests.invite') }}
                  </button>
                </div>
              </template>
            </TestCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue'

import { createToast } from 'mosha-vue-toastify'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import TestCard from '@/components/TestCard.vue'

import { storeToRefs } from 'pinia'
import { useAnalystTestsStore } from '@/stores/analystTests.store'
import { requestTest } from '@/services/analysts/test_requests'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

// This is used for "reloading" after inviting
const { fetchTestRequestsFromCandidate } = useAnalystTestsStore()

const props = defineProps({
  candidate: Object
})

// Get tests
const { fetchTests } = useAnalystTestsStore()
const { tests, loading } = storeToRefs(useAnalystTestsStore())
fetchTests()

// Candidate info to show in title
const candidateInfo = computed(() => {
  if (props.candidate.name) {
    return `${props.candidate.name} ${props.candidate.lastname}`
  } else {
    return props.candidate.email
  }
})

// Loading logic
const loadingInvitation = ref(false)
const loadingPage = computed(() => {
  return loading.value || loadingInvitation.value
})

// Invite candidate to test
const inviteCandidateToTest = async (testId) => {
  loadingInvitation.value = true
  try {
    await requestTest(testId, props.candidate)
    fetchTestRequestsFromCandidate(props.candidate.id)
    const message = t('analysts.requests.candidateInvitedSuccessfully')
    createToast(message, {
      hideProgressBar: true,
      type: 'success',
      showIcon: true,
      position: 'bottom-right'
    })
  } catch (e) {
    const message = t('analysts.requests.errorWhileInviting')
    createToast(message, {
      hideProgressBar: true,
      type: 'danger',
      showIcon: true,
      position: 'bottom-right'
    })
  }
  loadingInvitation.value = false
}
</script>
