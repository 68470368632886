export default {
  analysts: {
    tests: {
      title: 'Tests',
      yourTests: 'Tus test',
      invite: 'Invitar',
      seeRequests: 'Ver solicitudes',
      dataSheet: 'Ficha'
    },

    requests: {
      title: 'Solicitudes',
      noRequests: 'Aún no hay solicitudes registradas',
      searchByCandidate: 'Buscar por candidato',
      searchByTest: 'Buscar por test',
      test: 'Test',
      requestDate: 'Fecha solicitud',
      requestStatus: 'Estado de la solicitud',
      answered: 'Respondido',
      inProcess: 'En proceso',
      requestSent: 'Petición enviada',
      answerDate: 'Fecha respuesta',
      testResult: 'Resultado test',
      noResult: 'No hay resultado',
      report: 'Reporte',
      sendAgain: 'Re-enviar',
      remind: 'Recordar',
      candidate: 'Candidato',
      aboutToSendRequestAgain: 'Estás a punto de re-enviar un test',
      sendInvitation: 'Enviar invitación',
      candidateInvitedSuccessfully: 'Candidato invitado exitosamente',
      errorWhileInviting: 'Ha ocurrido un error al invitar al candidato',
      candidatesInvitedSuccessfully: 'Candidatos invitados exitosamente',
      reminderSentSuccessfully: 'Recordatorio enviado exitosamente',
      errorWhileSendingReminder: 'Ha ocurrido un error al enviar el recordatorio',
      inviteCandidateToTest: 'Invitar a {candidateInfo} a un test',
      requestsMade: 'Solicitudes realizadas',
      candidates: 'candidatos',
      byEmail: 'por correo',
      uploadCandidatesList: 'Carga tu lista de candidatos',
      file: 'Archivo',
      toAll: 'a todos',
      downloadTemplate: 'Descargar template',
      mustUploadFile: 'Debes subir un archivo',
      fileMustBeExcel: 'El archivo debe estar en formato Excel'
    },

    candidates: {
      title: 'Candidatos',
      add: 'Agregar',
      candidateAddedSuccessfully: 'Candidato agregado con éxito',
      errorAddingCandidate: 'Ha ocurrido un error al agregar al candidato',
      candidatesAddedSuccessfully: 'Candidatos agregados exitosamente',
      errorUploaddingData: 'Ha ocurrido un error al cargar los datos',
      noCandidates: 'Aún no hay candidatos registrados',
      searchCandidate: 'Buscar candidato',
      seeCandidate: 'Ver candidato'
    }
  }
}
