<template>
    <!-- Invite candidates to test -->
    <CandidatesForm
      formAction="invite"
      :showAccordion="testRequests.length === 0"
      :testId="testId"
    />
    <!-- Test requests -->
    <Loading v-model:active="loading" loader="dots"/>
    <div v-if="!loading">
      <RequestsList
        fromView="testRequests"
        :title="title"
        :originalObjectsArray="testRequests"
      />
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import CandidatesForm from '@/components/CandidatesForm.vue'
import RequestsList from '@/components/RequestsList.vue'

import { storeToRefs } from 'pinia'
import { useAnalystTestsStore } from '@/stores/analystTests.store'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { fetchTestRequestsFromTest } = useAnalystTestsStore()
const { test, testRequests, loading } = storeToRefs(useAnalystTestsStore())

// Get test id from params and get test requests
const route = useRoute()
const testId = parseInt(route.params.id)
fetchTestRequestsFromTest(testId)

// Get test name for requests title
const title = computed(() => {
  return `${t('analysts.requests.title')} ${test.value.name}`
})

</script>
