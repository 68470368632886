<template>
  <component
    v-if="authStore.user"
    :is="navbarType">
  </component>
  <div id="content-wrap">
    <RouterView/>
  </div>
  <Footer/>
</template>

<script setup>
import CandidatesNavbar from '@/components/layout/CandidatesNavbar.vue'
import AnalystsNavbar from '@/components/layout/AnalystsNavbar.vue'
import Footer from '@/components/layout/Footer.vue'
import { useAuthStore } from '@/stores/auth.store'
import { computed } from 'vue'

const authStore = useAuthStore()

const navbarType = computed(() => {
  return authStore.userType === 'candidate' ? CandidatesNavbar : AnalystsNavbar
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 5.5rem;    /* Footer height */
}

.w-45 {
  width: 45%;
}

.fw-500 {
  font-weight: 500;
}

.text-gray-600 {
color: $gray-600;
}

.general-btn {
  background-color: $polux-blue;
  border: solid;
  border-color: $polux-blue;
  border-radius: 30px;
  border-width: 1px;
  color: #FFF;
  margin: 0 5px;
  padding: 10px 32px;

  &:hover {
    background-color: transparent;
    color: $polux-blue;

    > a {
      color: $polux-blue;
    }
  }
}

.white-general-btn {
  background-color: transparent;
  border: solid;
  border-color: $polux-blue;
  border-radius: 30px;
  border-width: 1px;
  color: $polux-blue;
  padding: 10px 32px;

  &:hover {
    background-color: $polux-blue;
    color: #FFF;
  }
}

.pagination-container {
  column-gap: 10px;
  align-items: center;
  justify-content: center;
}
 .paginate-buttons {
  height: 35px;
  width: 35px;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  color: black;
}

 .back-button,
 .next-button {
  background-color: $polux-blue;
  color: white;
  border-radius: 8px;
  height: 45px;
  width: 45px;
}
 .active-page {
  background-color: #e5e5e5;
}
 .paginate-buttons:hover {
  background-color: #f5f5f5;
}
 .active-page:hover {
  background-color: #e5e5e5;
}

 .back-button svg {
  transform: rotate(180deg) translateY(-2px);
}
 .next-button svg {
  transform: translateY(2px);
}

 .back-button:hover,
 .next-button:hover {
  background-color: rgb(45, 45, 45);
}

 .back-button:active,
 .next-button:active {
  background-color: rgb(85, 85, 85);
}

.fancy-scrollbar::-webkit-scrollbar,
.fancy-scrollbar .dataTables_scrollBody::-webkit-scrollbar {
  width: 6px;
}

.fancy-scrollbar::-webkit-scrollbar-track-piece:vertical,
.fancy-scrollbar .dataTables_scrollBody::-webkit-scrollbar-track-piece:vertical {
  background-color: $gray-500;
  border-radius: 20px;
}

.fancy-scrollbar::-webkit-scrollbar-thumb:vertical,
.fancy-scrollbar .dataTables_scrollBody::-webkit-scrollbar-thumb:vertical {
  background-color: $gray-600;
  border-radius: 20px;
}

.h-90 {
  height: 90%;
}
</style>
