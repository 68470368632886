<template>
  <!-- Modal confirming invite again -->
  <DialogsWrapper />
  <!-- Loading -->
  <Loading v-model:active="loadingActions" loader="dots" />
  <!-- Table -->
  <div class="table-responsive mb-5">
    <table class="table">
      <tbody>
        <tr class="shadow-sm" v-for="request in props.paginatedObjects" :key="request.id">
          <!-- Candidate -->
          <td  v-if="props.fromView !== 'candidateRequests'" class="p-3">
            <div class="row">
              <span class="">{{ candidateFullName(request.candidate) }}</span>
            </div>
            <div class="row">
              <RouterLink :to="`/analysts/candidate-requests/${request.candidate.id}`" class="text-black text-decoration-none fw-bold">
                {{ request.candidate.email }}
              </RouterLink>
            </div>
          </td>
          <!-- Test -->
          <td v-if="props.fromView !== 'testRequests'" class="p-3">
            <div class="row">
              <span class="">{{ t('analysts.requests.test') }}</span>
            </div>
            <div class="row">
              <RouterLink :to="`/analysts/test-requests/${request.test.id}`" class="text-black text-decoration-none fw-bold">
                {{ request.test.name }}
              </RouterLink>
            </div>
          </td>
          <!-- Request date -->
          <td class="p-3">
            <div class="row">
              <span class="">{{ t('analysts.requests.requestDate') }}</span>
            </div>
            <div class="row">
              <b class="">{{ dateAndTime(request.test.sent_at) }}</b>
            </div>
          </td>
          <!-- Status -->
          <td class="p-3">
            <div class="row">
              <span class="">{{ t('analysts.requests.requestStatus') }}</span>
            </div>
            <div v-if="request.test.answered" class="row">
              <b class="">{{ t('analysts.requests.answered') }}</b>
            </div>
            <div v-else-if="request.test.started" class="row">
              <b class="">{{ t('analysts.requests.inProcess') }}</b>
            </div>
            <div v-else class="row">
              <b class="">{{ t('analysts.requests.requestSent') }}</b>
            </div>
          </td>
          <!-- Answer date -->
          <td class="p-3">
            <div class="row">
              <span class="">{{ t('analysts.requests.answerDate') }}</span>
            </div>
            <div class="row">
              <b class="">{{ answerDate(request) }}</b>
            </div>
          </td>
          <!-- Result -->
          <td class="p-3">
            <div class="row">
              <span class="">{{ t('analysts.requests.testResult') }}</span>
            </div>
            <div class="row">
              <b class="">{{
                request.result ? request.result.summary : t('analysts.requests.noResult')
              }}</b>
            </div>
          </td>
          <!-- Action: download Report -->
          <td class="p-3 col-1 text-center text-primary">
            <a
              v-if="request.result"
              :href="request.result.report_url"
              :download="`${t('analysts.requests.report')} ${request.test.name} - ${request.candidate.email}.pdf`"
              target="_blank"
              class="btn"
            >
              <div class="row">
                <small class=""><font-awesome-icon icon="chart-pie" /></small>
              </div>
              <div class="row">
                <small class="">{{ t('analysts.requests.report') }}</small>
              </div>
            </a>
            <button v-else class="btn disabled">
              <div class="row">
                <small class=""><font-awesome-icon icon="chart-pie" /></small>
              </div>
              <div class="row">
                <small class="">{{ t('analysts.requests.report') }}</small>
              </div>
            </button>
          </td>
          <!-- Action: Remind/reinvite -->
          <td class="p-3 col-2 text-center text-primary">
            <div
              v-if="request.test.answered"
              class="btn"
              @click="inviteAgain(request.test.id, request.candidate.email, request.candidate.id)"
            >
              <div class="row">
                <small class=""><font-awesome-icon icon="rotate-right" /></small>
              </div>
              <div class="row">
                <small class="">{{ t('analysts.requests.sendAgain') }}</small>
              </div>
            </div>
            <button
              v-else
              @click="remind(request.test.id, request.candidate.email)"
            >
              <div class="row">
                <small class=""><font-awesome-icon icon="rotate-right" /></small>
              </div>
              <div class="row">
                <small class="">{{ t('analysts.requests.remind') }}</small>
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import { dateAndTime } from '@/helpers/format-date'

import { requestTest, remindTest } from '@/services/analysts/test_requests'
import { useAnalystTestsStore } from '@/stores/analystTests.store'

import { createToast } from 'mosha-vue-toastify'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  paginatedObjects: Array,
  fromView: String
})

const answerDate = (request) => {
  const date = request.answer?.end_date
  return date ? dateAndTime(date) : '-'
}

const candidateFullName = (candidate) => {
  const fullname = candidate.name
    ? `${candidate.name} ${candidate.lastname}`
    : t('analysts.requests.candidate')
  return fullname
}

// "Reloading" after inviting again
const { fetchTestRequestsFromCandidate, fetchAllTestRequests, fetchTestRequestsFromTest } = useAnalystTestsStore()

const reloadRequests = (testId, candidateId) => {
  switch (props.fromView) {
    case 'testRequests':
      fetchTestRequestsFromTest(testId)
      break
    case 'candidateRequests':
      fetchTestRequestsFromCandidate(candidateId)
      break
    case 'requests':
      fetchAllTestRequests()
      break
  }
}

/*
  Actions
*/

const loadingActions = ref(false)
const dialog = createConfirmDialog(ConfirmationModal)

const inviteAgain = async (testId, candidateEmail, candidateId) => {
  const confirmation = await dialog.reveal({
    icon: 'circle-info',
    title: t('analysts.requests.aboutToSendRequestAgain'),
    text: t('confirmAction'),
    confirmBtnText: t('analysts.requests.sendInvitation'),
    cancelBtnText: t('back')
  })

  if (!confirmation.isCanceled) {
    const candidateAttributes = {
      email: candidateEmail
    }
    loadingActions.value = true
    try {
      await requestTest(testId, candidateAttributes)
      reloadRequests(testId, candidateId)
      const message = t('analysts.requests.candidateInvitedSuccessfully')
      createToast(message, {
        hideProgressBar: true,
        type: 'success',
        showIcon: true,
        position: 'bottom-right'
      })
    } catch (e) {
      const message = t('analysts.requests.errorWhileInviting')
      createToast(message, {
        hideProgressBar: true,
        type: 'danger',
        showIcon: true,
        position: 'bottom-right'
      })
    }
    loadingActions.value = false
  }
}

const remind = async (testId, candidateEmail) => {
  loadingActions.value = true
  try {
    await remindTest(testId, candidateEmail)
    const message = t('analysts.requests.reminderSentSuccessfully')
    createToast(message, {
      hideProgressBar: true,
      type: 'success',
      showIcon: true,
      position: 'bottom-right'
    })
  } catch (e) {
    const message = t('analysts.requests.errorWhileSendingReminder')
    createToast(message, {
      hideProgressBar: true,
      type: 'danger',
      showIcon: true,
      position: 'bottom-right'
    })
  }
  loadingActions.value = false
}
</script>

<style lang="scss" scoped>
button {
  all: unset;
  cursor: pointer;
}
</style>
