<template>
  <div>
    <form class="mt-3" autocomplete="off" @submit.prevent="updatePassword">
      <!-- Email (disabled) -->
      <div class="mb-3">
        <label for="email" class="form-label text-primary fw-bold">{{ $t('login.email') }}</label>
        <input
          type="email"
          disabled="true"
          class="form-control"
          id="email"
          v-model="formData.email"
        />
      </div>
      <!-- Current password -->
      <div class="mb-3" v-if="props.needsPassword">
        <label for="current-password" class="form-label text-primary fw-bold">{{ $t('account.currentPassword') }}</label>
        <input
          type="password"
          autocomplete="off"
          :placeholder="$t('account.currentPassword')"
          class="form-control"
          id="current-password"
          v-model="formData.currentPassword"
          required
        />
      </div>
      <!-- New password -->
      <div class="mb-3">
        <label for="new-password" class="form-label text-primary fw-bold">{{ $t('account.newPassword') }}</label>
        <input
          type="password"
          autocomplete="off"
          :placeholder="$t('account.newPassword')"
          class="form-control"
          id="new-password"
          v-model="formData.newPassword"
          required
        />
      </div>
      <!-- New password confirmation -->
      <div class="mb-3">
        <label for="new-password-confirmation" class="form-label text-primary fw-bold">{{ $t('account.passwordConfirmation') }}</label>
        <input
          type="password"
          :placeholder="$t('account.passwordConfirmation')"
          class="form-control"
          id="new-password-confirmation"
          autocomplete="off"
          v-model="formData.newPasswordConfirmation"
          required
        />
        <p class="mt-2 text-danger" v-if="formData.newPasswordConfirmation != formData.newPassword">* {{ $t('account.passwordsDontMatch') }}</p>
      </div>

      <div class="row mt-4 text-center">
        <div class="col-md-12">
          <RouterLink :to="`/${userType}s/account/personal-info`" class="btn white-general-btn">{{ $t('back') }}</RouterLink>
          <button class="general-btn rounded-pill m-lg-4 mt-4" :disabled="formData.newPasswordConfirmation != formData.newPassword">{{ $t('account.changePassword') }}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps } from 'vue'
import { useAuthStore } from '@/stores/auth.store'
const authStore = useAuthStore()

const userType = ref(authStore.userType)

const props = defineProps({
  id: Number,
  email: String,
  needsPassword: Boolean,
  updatePassword: Function
})

const formData = reactive({
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
  email: props.email
})

const updatePassword = () => {
  const params = {
    id: props.id,
    current_password: formData.currentPassword,
    new_password: formData.newPassword,
    new_password_confirmation: formData.newPasswordConfirmation
  }
  props.updatePassword(params)
}
</script>
