import { fetchWrapper } from '@/helpers/fetch-wrapper'

const requestTest = (testId, candidateData) => {
  const url = `${process.env.VUE_APP_API_BASE}/analysts/test_requests`
  const params = {
    test_request: {
      test_id: testId,
      candidate_attributes: {
        email: candidateData.email,
        name: candidateData.name,
        lastname: candidateData.lastname
      }
    }
  }
  return fetchWrapper.post(url, params)
}

const remindTest = (testId, candidateEmail) => {
  const url = `${process.env.VUE_APP_API_BASE}/analysts/mails/test_reminders`
  const params = {
    test_request: {
      test_id: testId,
      candidate_attributes: {
        email: candidateEmail
      }
    }
  }
  return fetchWrapper.post(url, params)
}

const batchInviteCandidates = (file, testId) => {
  const url = `${process.env.VUE_APP_API_BASE}/analysts/batch/test_requests`
  const params = {
    test_id: testId,
    file: file
  }
  return fetchWrapper.post(url, params)
}

export {
  requestTest,
  remindTest,
  batchInviteCandidates
}
