<template>
  <!-- Candidate Requests -->
  <Loading v-model:active="loading" loader="dots"/>
  <div v-if="!loading">
    <RequestsList
      fromView="candidateRequests"
      :title="title"
      :originalObjectsArray="testRequests"
    />
  </div>
  <!-- Invite to test -->
  <InviteCandidate :candidate="candidate" />
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import RequestsList from '@/components/RequestsList.vue'
import InviteCandidate from '@/components/InviteCandidate.vue'

import { storeToRefs } from 'pinia'
import { useAnalystTestsStore } from '@/stores/analystTests.store'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { fetchTestRequestsFromCandidate } = useAnalystTestsStore()
const { candidate, testRequests, loading } = storeToRefs(useAnalystTestsStore())

// Get candidate id from params and get test requests
const route = useRoute()
const candidateId = route.params.id
fetchTestRequestsFromCandidate(candidateId)

// Get candidate name for requests title
const title = computed(() => {
  const fullname = candidate.value.name
    ? `${candidate.value.name} ${candidate.value.lastname}`
    : candidate.value.email
  return `${t('analysts.requests.title')} ${fullname}`
})
</script>
