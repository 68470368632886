<template>
  <div>
    <div class="container">
      <br>
      <br>
      <Loading v-model:active="loading" loader="dots"/>
      <!-- Pending tests -->
      <div class="row">
        <div class="col-sm-12">
          <h2 class="text-primary tests-title">{{ $t('candidates.home.pendingRequests') }}</h2>
          <div class="p-2" v-if="pendingTests.length !== 0">
            <ul class="row row-cols-1 row-cols-lg-2 ps-0 mb-5">
              <li class="col" v-for="pendingTestRequest in pendingTests" :key="pendingTestRequest.id">
                <div class="row shadow-sm p-3 mx-1 request-row">
                  <div class="col-sm-7">
                    <div class="col-sm-12 overflow-ellipsis">
                      <span class="me-1"><font-awesome-icon icon="building" /></span>
                      <small class="me-1">{{ $t('candidates.home.company') }}:</small>
                      <small class="fw-bold">{{ pendingTestRequest.company.name }}</small>
                    </div>
                    <div class="col-sm-12">
                      <span class="me-1"><font-awesome-icon icon="calendar" /></span>
                      <small class="me-1">{{ $t('candidates.home.requestDate') }}:</small>
                      <small class="fw-bold">{{ dateAndTime(pendingTestRequest.sent_at) }}</small>
                    </div>
                  </div>
                  <div class="col-sm-5 see-detail-btn">
                    <div v-if="pendingTestRequest.test.started">
                      <span class="btn general-btn disabled">{{ $t('candidates.home.inProcess') }}</span>
                    </div>
                    <div v-else>
                      <RouterLink :to="`test-detail/${pendingTestRequest.id}`" class="btn general-btn">{{ $t('candidates.home.goToDetail') }}</RouterLink>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="pt- tests-title" v-else-if="!loading">{{ $t('candidates.home.noPendingRequests') }}</div>
        </div>
      </div>
      <br>

      <!-- Answered tests -->
      <div class="col-sm-12">
        <h2 class="text-primary tests-title">{{ $t('candidates.home.completedRequests') }}</h2>
        <div class="p-2" v-if="answeredTests.length !== 0">
          <ul class="row row-cols-1 row-cols-lg-2 ps-0 mb-5">
            <li class="col" v-for="answeredTest in answeredTests" :key="answeredTest.id">
              <div class="row shadow p-3 mx-1 request-row muted mb-1">
                <div class="col-sm-7">
                  <div class="col-sm-12 overflow-ellipsis">
                    <span class="me-1"><font-awesome-icon icon="building" /></span>
                    <small class="me-1">{{ $t('candidates.home.company') }}:</small>
                    <small class="fw-bold">{{ answeredTest.company.name }}</small>
                  </div>
                  <div class="col-sm-12">
                    <span class="me-1"><font-awesome-icon icon="calendar" /></span>
                    <small class="me-1">{{ $t('candidates.home.requestDate') }}:</small>
                    <small class="fw-bold">{{ dateAndTime(answeredTest.sent_at) }}</small>
                  </div>
                  <div class="col-sm-12">
                    <span class="me-1"><font-awesome-icon icon="calendar" /></span>
                    <small class="me-1">{{ $t('candidates.home.answerDate') }}:</small>
                    <small class="fw-bold">{{ dateAndTime(answeredTest.test.end_date) }}</small>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="pt-2 tests-title" v-else-if="!loading">{{ $t('candidates.home.noCompletedRequests') }}</div>
      </div>

    </div>
  </div>
</template>

<script setup>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { storeToRefs } from 'pinia'
import { useCandidateTestsStore } from '@/stores/candidateTests.store'
import { dateAndTime } from '@/helpers/format-date'

const { pendingTests, answeredTests, loading } = storeToRefs(useCandidateTestsStore())
const { fetchTests } = useCandidateTestsStore()

fetchTests()

</script>

<style lang="scss" scoped>
  .general-btn {
    padding: 10px 15px;
  }

  ul {
    list-style-type: none;
  }

  .muted {
    background-color: #f2f2f2;
    color: #9a9a9a;
  }

  .overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media (max-width: 577.98px) {
    .see-detail-btn {
      margin-top: 1rem;
    }
    .request-row {
      text-align: center;
    }
    .general-btn {
      width: 70%;
    }
    .tests-title {
      text-align: center;
    }
  }

  @media (min-width: 578px) {
    .general-btn {
      float: right;
    }
  }
</style>
