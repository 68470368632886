<template>
  <div
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="toggleActive"
    :class="{ 'active-dropzone': active }"
    class="dropzone"
  >
      <label for="dropzoneFile"><i class="far fa-inbox"></i></label>
      <span class="mb-1"><font-awesome-icon icon="arrow-up-from-bracket" /></span>
      <span class="mb-1">{{ $t('dragAndDropToUploadFile') }}</span>
      <span class="text-gray-600">{{ $t('maxFileSize') }} {{ $t('excelFormat') }}</span>
      <input type="file" id="dropzoneFile" class="dropzoneFile">
  </div>
</template>

<script setup>
import { ref } from 'vue'

const active = ref(false)
const toggleActive = () => {
  active.value = !active.value
}
</script>

<style scoped lang="scss">
  .dropzone {
    width: 60%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid $gray-500;
    border-radius: 2px;
    background: $gray-100;
    padding: 3%;
    transition: 0.3s ease all;
  }

  .active-dropzone {
    color: rgba($gray-500, .5);
  }

  input {
    display: none;
  }
</style>
