<template>
  <div>
    <div class="container text-center text-sm-start">
      <div class="row shadow p-5 mt-5">
        <div class="col-sm-6">
          <div>
            <img src="../../assets/img/finished-test.svg" alt="by-polux" class="finished-test-img">
          </div>
        </div>
        <div class="col-sm-6">
          <h2 class="text-primary">{{ $t('candidates.tests.finishedTest') }}</h2>
          <br>
          <p class="text-primary">{{ $t('candidates.tests.youHaveFinishedTheTest') }}</p>
          <br>
          <RouterLink :to="{name: 'candidatesTests'}" :class="'btn general-btn'">{{ $t('candidates.tests.backToHome') }}</RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.finished-test-img {
  width: 80%
}
</style>
