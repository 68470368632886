<template>
  <div class="container px-0">
    <slot name="header"></slot>
    <div class="row">
      <div v-if="props.questionNumber" class="col-sm-1 mb-2">
        <b class="badge bg-primary">{{ props.questionNumber }}</b>
      </div>
      <div class="statement-box col-sm-10 fw-bold">
        {{ question.statement }}
      </div>
      <div class="row">
        <div :id="`text-container-form-${props.questionNumber}`" class="col-sm-12 offset-sm-1 mt-2">
          <div v-if="props.isExample" class="">
            <input
              type="text"
              :placeholder="props.placeholder"
              :id="`example-question-${props.questionNumber}`"
              class="form-control w-25">
          </div>
          <div v-else class="">
            <input
              type="text"
              :placeholder="props.placeholder"
              v-model="answers[question.id]"
              :id="`question-${question.id}`"
              class="form-control w-25">
          </div>
        </div>
      </div>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'

const props = defineProps({
  question: Object,
  questionNumber: Number,
  placeholder: String,
  answersForm: Object,
  isExample: Boolean
})

const answers = ref(props.answersForm)
</script>

<style scoped lang="scss">
  .statement-box {
    white-space:pre-wrap;
  }
</style>
