<template>
  <div class="shadow p-3 mx-auto my-5 rounded-8 w-75 bg-white">
    <!-- Title -->
    <h2 class="p-3 text-primary">{{ props.title }}</h2>
    <!-- No requests -->
    <div v-if="props.originalObjectsArray.length === 0" class="p-3 text-primary mb-5">
      {{ t('analysts.requests.noRequests') }}
    </div>
    <!-- Requests -->
    <div class="p-3" v-else>
      <!-- Search Bar -->
      <div class="mb-4">
        <SearchBar
          :placeholder="searchBarPlaceholder"
          v-model="searchInput"
        />
      </div>
      <!-- No results -->
      <div v-if="filteredObjects.length === 0" class="p-3 text-primary mb-5">
        {{ t('noSearchResults') }}
      </div>
      <!-- Requests Table with pagination -->
      <div v-else>
        <RequestsTable
          :paginatedObjects="paginatedObjects"
          :fromView="props.fromView"
        />
        <div class="text-center">
          <VueAwesomePaginate
            :key="paginationKey"
            :total-items="filteredObjects.length"
            :items-per-page="nEntries"
            :max-pages-shown="8"
            v-model="currentPage"
            @click="onClickHandler"
            :back-button-class="currentPage === 1 ? 'd-none' : 'back-button'"
            :next-button-class="currentPage === nPages ? 'd-none' : 'next-button'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'

import RequestsTable from '@/components/RequestsTable.vue'

import SearchBar from '@/components/SearchBar.vue'
import { usePaginationAndSearch } from '@/use/usePaginationAndSearch'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  title: String,
  fromView: String,
  originalObjectsArray: Array
})

/*
  Search and Pagination
*/

// nEntries needed for pagination
const nEntries = 4
// Objetcs attributes to search by
let filterableAttributes = []
// This is needed because objects to search into, are nested
let nestedAttribute = []
// Search bar placeholder depends on what can be looked for
let searchBarPlaceholder = ''

switch (props.fromView) {
  case 'testRequests':
    // When test is fixed, you look for candidate info (name, lastname, email)
    nestedAttribute = 'candidate'
    filterableAttributes = ['name', 'lastname', 'email']
    searchBarPlaceholder = `${t('analysts.requests.searchByCandidate')}...`
    break
  case 'candidateRequests':
    // When candidate is fixed, you look for test info (name)
    nestedAttribute = 'test'
    filterableAttributes = ['name']
    searchBarPlaceholder = `${t('analysts.requests.searchByTest')}...`
    break
  case 'requests':
    // When nothing is fixed, in the meantime, you can ony look for candidate info
    nestedAttribute = 'candidate'
    filterableAttributes = ['name', 'lastname', 'email']
    searchBarPlaceholder = `${t('analysts.requests.searchByCandidate')}...`
    break
}

// Search bar component emits the string that is being searched and it is updated in this ref
const searchInput = ref('')
// When this ref changes, the computed from the usePaginationAndSearch composable is executed
// and the results of the search are returned in filteredObjects
// That composable also returns the objects filtered and paginated (paginatedObjects)

const {
  filteredObjects,
  paginatedObjects,
  currentPage,
  paginationKey,
  nPages,
  onClickHandler
} = usePaginationAndSearch(
  props,
  searchInput,
  filterableAttributes,
  nEntries,
  true,
  nestedAttribute
)
</script>
