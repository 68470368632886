export default {
  candidates: {
    home: {
      title: 'Início',
      pendingRequests: 'Solicitações pendentes',
      company: 'Empresa',
      requestDate: 'Data da solicitação',
      inProcess: 'Em processo',
      goToDetail: 'Ir para detalhes',
      noPendingRequests: 'Você não tem solicitações pendentes',
      completedRequests: 'Solicitações completadas',
      answerDate: 'Data da resposta',
      noCompletedRequests: 'Você ainda não completou nenhuma solicitação'
    },

    tests: {
      abandonTest: 'Abandonar teste',
      confirmAbandonTest: 'Se você abandonar o teste, ele será considerado como respondido e você não poderá continuar. Deseja abandonar?',
      aboutToLeaveTest: 'Você está prestes a abandonar o teste',
      abandon: 'Abandonar',
      backToTest: 'Voltar ao teste',
      timeOver: 'Tempo Limite Excedido',
      youHaveReachedTimeLimit: 'Você excedeu o tempo limite do teste.',
      lastQuestionSubmitted: 'A última pergunta que você respondeu foi enviada.',
      finishedTest: 'Teste Concluído',
      youHaveFinishedTheTest: 'Você concluiu o teste com sucesso. Verifique se você tem mais solicitações pendentes.',
      backToHome: 'Voltar para o início',
      testNotFound: 'Teste não encontrado',
      environmentRecommendation: 'Recomendamos responder ao teste em um computador e em um ambiente tranquilo, sem distrações.',
      requestingCompany: 'Empresa solicitante',
      testInstructions: 'Instruções do teste',
      startTest: 'Iniciar teste',
      // Common Test Views
      instructions: 'Instruções',
      example: 'Exemplo',
      previous: 'Anterior',
      next: 'Próximo',
      sendAnswers: 'Enviar respostas',
      aboutToSendAnswers: 'Você está prestes a enviar suas respostas',
      mustChooseAnAlternative: 'Você deve escolher uma alternativa para continuar',
      always: 'Sempre ou quase sempre',
      often: 'Frequentemente',
      occasionally: 'Ocasionalmente',
      never: 'Raramente ou nunca',
      startPartOne: 'Iniciar parte I',
      startPartTwo: 'Iniciar parte II',
      totallyAgree: 'Totalmente de acordo',
      agree: 'De acordo',
      neutral: 'Neutro',
      disagree: 'Discordo',
      totallyDisagree: 'Totalmente em desacordo',
      // Particular Test Views
      complexInstructions: {
        insuredAmount: 'Valor segurado',
        insuranceClass: 'Classe de seguro',
        date: 'Data'
      },
      disc: {
        profileSystem: 'Sistema de perfil pessoal',
        blankOptionsWarning: 'Você deve escolher uma opção para MAIS e uma opção para MENOS',
        sameOptionWarning: 'Você não pode escolher a mesma opção',
        more: 'MAIS',
        less: 'MENOS'
      },
      wonderlic: {
        answerHonestly: 'Responda honestamente de acordo com seu critério',
        exampleQuestion: 'Exemplo de pergunta'
      }
    }
  }
}
