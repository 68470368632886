import { createApp } from 'vue'
import { createPinia } from 'pinia'

import 'bootstrap/dist/css/bootstrap.min.css'
import '@/scss/bootstrap-custom.scss'
import 'bootstrap'
import 'mosha-vue-toastify/dist/style.css'

// Components
import VueAwesomePaginate from 'vue-awesome-paginate'
import 'vue-awesome-paginate/dist/style.css'
import FontAwesomeIcon from '@/helpers/fontawesome-icons'
import * as ConfirmDialog from 'vuejs-confirm-dialog'

// Methods
import VueMobileDetection from 'vue-mobile-detection'

import App from './App.vue'
import router from './router'

import i18n from './i18n'

createApp(App)
  .use(createPinia())
  .use(router)
  .use(VueAwesomePaginate)
  .use(VueMobileDetection)
  .use(ConfirmDialog)
  .use(i18n())
  .component('FontAwesomeIcon', FontAwesomeIcon)
  .mount('#app')
