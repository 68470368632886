export default {
  analysts: {
    tests: {
      title: 'Testes',
      yourTests: 'Seus testes',
      invite: 'Convidar',
      seeRequests: 'Ver solicitações',
      dataSheet: 'Ficha'
    },

    requests: {
      title: 'Solicitações',
      noRequests: 'Ainda não há solicitações registradas',
      searchByCandidate: 'Buscar por candidato',
      searchByTest: 'Buscar por teste',
      test: 'Teste',
      requestDate: 'Data da solicitação',
      requestStatus: 'Status da solicitação',
      answered: 'Respondida',
      inProcess: 'Em processo',
      requestSent: 'Solicitação enviada',
      answerDate: 'Data da resposta',
      testResult: 'Resultado do teste',
      noResult: 'Sem resultado',
      report: 'Relatório',
      sendAgain: 'Reenviar',
      remind: 'Lembrar',
      candidate: 'Candidato',
      aboutToSendRequestAgain: 'Você está prestes a reenviar um teste',
      sendInvitation: 'Enviar convite',
      candidateInvitedSuccessfully: 'Candidato convidado com sucesso',
      errorWhileInviting: 'Ocorreu um erro ao convidar o candidato',
      candidatesInvitedSuccessfully: 'Candidatos convidados com sucesso',
      reminderSentSuccessfully: 'Lembrete enviado com sucesso',
      errorWhileSendingReminder: 'Ocorreu um erro ao enviar o lembrete',
      inviteCandidateToTest: 'Convidar {candidateInfo} para um teste',
      requestsMade: 'Solicitações feitas',
      candidates: 'candidatos',
      byEmail: 'por e-mail',
      uploadCandidatesList: 'Carregar sua lista de candidatos',
      file: 'Arquivo',
      toAll: 'para todos',
      downloadTemplate: 'Baixar modelo',
      mustUploadFile: 'Você deve fazer o upload de um arquivo',
      fileMustBeExcel: 'O arquivo deve estar em formato Excel'
    },

    candidates: {
      title: 'Candidatos',
      add: 'Adicionar',
      candidateAddedSuccessfully: 'Candidato adicionado com sucesso',
      errorAddingCandidate: 'Ocorreu um erro ao adicionar o candidato',
      candidatesAddedSuccessfully: 'Candidatos adicionados com sucesso',
      errorUploaddingData: 'Ocorreu um erro ao carregar os dados',
      noCandidates: 'Ainda não há candidatos registrados',
      searchCandidate: 'Buscar candidato',
      seeCandidate: 'Ver candidato'
    }
  }
}
