import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetch-wrapper'
import { createToast } from 'mosha-vue-toastify'
import { useAuthStore } from '@/stores/auth.store'

import i18n, { setI18nLanguage } from '../i18n'

const { t } = i18n().global

const handleResponse = (message, type) => {
  createToast(message, {
    hideProgressBar: true,
    type: type, // 'danger' or 'success'
    showIcon: true,
    position: 'bottom-right'
  })
}

export const useCandidateStore = defineStore({
  id: 'candidate',
  state: () => ({
    // initialize state properties
    candidateId: null,
    name: null,
    lastname: null,
    email: null,
    language: null,
    error: null,
    loading: false
  }),
  actions: {
    // Get candidate's personal data.
    fetchPersonalInfo () {
      this.candidateId = null
      this.name = null
      this.lastname = null
      this.email = null
      this.language = null
      this.error = null
      this.loading = true
      fetchWrapper.get(`${process.env.VUE_APP_API_BASE}/candidates/candidates`)
        .then((response) => {
          this.candidateId = response.id
          this.name = response.name
          this.lastname = response.lastname
          this.email = response.email
          this.language = response.language
        }).catch((error) => {
          this.error = error
          const message = t('genericError')
          handleResponse(message, 'danger')
        }).finally(() => {
          this.loading = false
        })
    },
    // Update candidate personal data.
    updatePersonalInfo (candidateId, params) {
      this.loading = true
      this.error = null
      fetchWrapper.put(`${process.env.VUE_APP_API_BASE}/candidates/candidates/${candidateId}`, params)
        .then((response) => {
          const candidate = response.candidate
          this.name = candidate.name
          this.lastname = candidate.lastname
          this.email = candidate.email
          this.language = candidate.language
          setI18nLanguage(this.language)
          const message = t('dataUpdatedSuccessfully')
          handleResponse(message, 'success')
        }).catch((error) => {
          this.error = error
          const message = t('genericError')
          handleResponse(message, 'danger')
        }).finally(() => {
          this.loading = false
        })
    },
    // Update passsword
    updatePassword (params) {
      const { authorizeWithToken } = useAuthStore()
      this.loading = true
      fetchWrapper.put(`${process.env.VUE_APP_API_BASE}/candidates/sessions`, params)
        .then((response) => {
          // Update candidate credentials store
          const candidate = {
            type: 'candidate',
            credentials: { token: response.token }
          }
          authorizeWithToken(candidate, response.language)
          const message = t('passwordUpdatedSuccessfully')
          handleResponse(message, 'success')
        }).catch((error) => {
          this.error = error
          const message = t('genericError')
          handleResponse(message, 'danger')
        }).finally(() => {
          this.loading = false
        })
    }
  }
})
