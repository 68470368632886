<template>
  <div class="shadow p-3 mx-auto my-5 rounded-8 w-75 bg-white">
    <h2 class="p-3 text-primary">{{ $t('analysts.candidates.title') }}</h2>

    <div
      v-if="props.originalObjectsArray && props.originalObjectsArray.length === 0"
      class="p-3 text-primary mb-5"
    >
      {{ $t('analysts.candidates.noCandidates') }}
    </div>

    <div class="p-3" v-else>
      <div class="mb-4">
        <SearchBar :placeholder="`${$t('analysts.candidates.searchCandidate')}...`" v-model="searchInput" />
      </div>

      <div
        v-if="filteredObjects && filteredObjects.length === 0"
        class="p-3 text-primary mb-5"
      >
        {{ $t('noSearchResults') }}
      </div>

      <div v-else>
        <ul class="row row-cols-1 row-cols-lg-2 ps-0 mb-5">
          <li
            class="col"
            v-for="candidate in paginatedObjects"
            :key="candidate.id"
          >
            <div class="row row-cols-1 row-cols-lg-2 shadow-sm p-3 mx-1">
              <div class="col">
                <div>
                  {{ fullname(candidate) }}
                </div>
                <div>
                  <b>{{ candidate.email }}</b>
                </div>
              </div>
              <div class="col">
                <div>
                  <RouterLink
                    :to="`/analysts/candidate-requests/${candidate.id}`"
                    class="btn general-btn"
                    >{{ $t('analysts.candidates.seeCandidate') }}</RouterLink
                  >
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div class="text-center">
          <VueAwesomePaginate
            :key="paginationKey"
            :total-items="filteredObjects.length"
            :items-per-page="nEntries"
            :max-pages-shown="8"
            v-model="currentPage"
            @click="onClickHandler"
            :back-button-class="currentPage === 1 ? 'd-none' : 'back-button'"
            :next-button-class="currentPage === nPages ? 'd-none' : 'next-button'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import SearchBar from '@/components/SearchBar.vue'
import { usePaginationAndSearch } from '@/use/usePaginationAndSearch'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  originalObjectsArray: Array
})

const fullname = (candidate) => {
  const fullname = candidate.name
    ? `${candidate.name} ${candidate.lastname}`
    : t('analysts.requests.candidate')
  return fullname
}
/*
  Search and Pagination
*/

const searchInput = ref('')
const nEntries = 8
const filterableAttributes = ['name', 'lastname', 'email']

const {
  filteredObjects,
  paginatedObjects,
  currentPage,
  paginationKey,
  nPages,
  onClickHandler
} = usePaginationAndSearch(props, searchInput, filterableAttributes, nEntries)
</script>

<style lang="scss" scoped>
.general-btn {
  float: right;
}

ul {
  list-style-type: none;
}
</style>
