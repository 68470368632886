<template>
  <DialogsWrapper v-if="!loading"/>
  <Loading v-model:active="loading" loader="dots"/>
  <div>
    <div v-if="testViewData" class="container mt-5 bg-white shadow p-3">
      <!-- Instructions -->
      <div class="p-3">
        <div>
          {{ testViewData.instruction }}
        </div>
      </div>
      <!-- Warning -->
      <div v-if="warnings.blankOption" class="pt-3 ps-3">
        <p class="text-danger mb-0 py-1 fw-500">{{ $t('candidates.tests.mustChooseAnAlternative') }}</p>
      </div>
      <hr>
      <!-- Questions -->
      <div class="p-3">
        <div v-for="gIndex in maxQuestions" :key="gIndex">
          <TextAlternativeQuestion
            v-if="currentQuestion === gIndex"
            :question="testViewData.questions[gIndex-1]"
            :gIndex="gIndex"
            :alternatives="makeAlternatives(testViewData.questions[gIndex-1].choices)"
            :answersForm="answersForm"
            :questionNumber="currentQuestion"
          />
        </div>
        <!-- End -->
        <div class="py-3 buttons-container">
          <button v-if="currentQuestion !== 1" @click="nextQuestion('back')" class="btn white-general-btn mx-2 my-1">{{ $t('candidates.tests.previous') }}</button>
          <button v-if="currentQuestion < maxQuestions" @click="nextQuestion('next')" class="btn white-general-btn mx-2 my-1">{{ $t('candidates.tests.next') }}</button>
          <button v-if="currentQuestion === maxQuestions" @click="sendAnswers(testAnswer.id, testAnswer.test_request_id)" class="btn general-btn mx-2 my-1">{{ $t('candidates.tests.sendAnswers') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useCandidateTestsStore } from '@/stores/candidateTests.store'
import TextAlternativeQuestion from '@/components/tests/TextAlternativeQuestion.vue'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import Loading from 'vue-loading-overlay'
import { useNavigationGuard } from '@/use/useNavigationGuard'
import 'vue-loading-overlay/dist/vue-loading.css'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { updateAnswer, toggleTestStatus, fetchTestData } = useCandidateTestsStore()
const { testViewData, testAnswer, loading, testInProgress } = storeToRefs(useCandidateTestsStore())

const maxQuestions = 185
const currentQuestion = ref(1)
const answersForm = ref({})

const confirmSendAnswersDialog = createConfirmDialog(ConfirmationModal)
const route = useRoute()

const warnings = reactive({
  blankOption: false
})
/*
  Get test questions form and create empty answer.
*/

onBeforeMount(() => {
  fetchTestData(+route.params.id)
})

/*
  Navigation guard
*/

useNavigationGuard(testInProgress, () => {
  const params = {
    test_answer: {
      answer: answersForm.value,
      test_request_id: testAnswer.value?.test_request_id
    }
  }
  toggleTestStatus(false)
  updateAnswer(testAnswer.value?.id, params, true)
})

/*
  Test in progress
*/

onMounted(() => {
  toggleTestStatus(true)
})

/*
  Questions logic
*/

const nextQuestion = (direction) => {
  switch (direction) {
    case 'next':
      if (validateQuestionInputs(currentQuestion.value)) {
        currentQuestion.value += 1
        warnings.blankOption = false
      } else {
        warnings.blankOption = true
      }
      break
    case 'back':
      warnings.blankOption = false
      currentQuestion.value -= 1
      break
  }
}

const validateQuestionInputs = (questionIndex) => {
  return answersForm.value[questionIndex] !== undefined
}

const makeAlternatives = (choices) => {
  const alternatives = []
  choices.forEach(function (choice, index) {
    let value = 'A'
    if (index === 1) {
      value = 'B'
    } else if (index === 2) {
      value = 'C'
    }
    const alternative = {
      text: choice,
      value: value
    }
    alternatives.push(alternative)
  })
  return alternatives
}

/*
  Send answers
*/

const sendAnswers = async (testAnswerId, testRequestId) => {
  const params = {
    test_answer: {
      answer: answersForm.value,
      test_request_id: testRequestId
    }
  }
  const confirmation = await confirmSendAnswersDialog.reveal({
    icon: 'circle-info',
    title: t('candidates.tests.aboutToSendAnswers'),
    text: t('confirmAction'),
    confirmBtnText: t('candidates.tests.sendAnswers'),
    cancelBtnText: t('candidates.tests.backToTest')
  })
  // On confirm, change test status and update answer
  if (!confirmation.isCanceled) {
    toggleTestStatus(false)
    updateAnswer(testAnswerId, params, true)
  }
}
</script>

<style scoped>
  .buttons-container{
    text-align: end;
  }
  @media (max-width: 1199.98px) {
    .buttons-container{
    text-align: center;
    }
  }
</style>
