<template>
  <DialogsWrapper v-if="!loading"/>
  <Loading v-model:active="loading" loader="dots"/>
  <div  v-if="!timeOver">
    <div class="container mt-5 bg-white shadow p-3">
      <div class="row">
        <div v-if="testViewData" class="col-sm-12">
          <div clas="row">
            <div>
              <h2 class="p-3 text-primary">{{ $t('candidates.tests.instructions') }}:</h2>
              <ol>
                <li v-for="(instruction, index) in testViewData.instructions" :key="index">
                  {{ instruction }}
                </li>
              </ol>
            </div>
          </div>
          <br>
          <hr>
          <div class="p-3">
            <!-- Example -->
            <div v-if="currentGroup === 0">
              <AlternativeImageQuestion
                testName="alert_test"
                :question="{id: -1}"
                :alternatives="alternatives"
                :answersForm="answersForm"
                :isExample="true"
              >
                <template v-slot:example>
                  <h2>{{ $t('candidates.tests.example') }}:</h2>
                  <p>{{ testViewData.example.text }}</p>
                </template>
              </AlternativeImageQuestion>
              <div class="mb-3 text-primary fw-bold">
                {{ testViewData.start_test_instruction }}
              </div>
            </div>
            <!-- Questions -->
            <div v-for="index in maxQuestions" :key="index">
              <AlternativeImageQuestion
                v-if="currentGroup === index"
                testName="alert_test"
                :question="{id: index}"
                :questionNumber="index"
                :alternatives="alternatives"
                :answersForm="answersForm"
                :showQuestionNumber="false"
              >
                <template v-slot:buttons>
                  <div class="py-3 buttons-container row justify-content-center" v-if="currentGroup !== 0">
                    <button v-if="currentGroup !== 1" @click="nextGroup('back')" class="btn white-general-btn col-6 col-md-5 col-lg-4 col-xl-3 mx-2 my-1">{{ $t('candidates.tests.previous') }}</button>
                    <button v-if="currentGroup < maxQuestions" @click="nextGroup('next')" class="btn white-general-btn col-6 col-md-5 col-lg-4 col-xl-3 mx-2 my-1">{{ $t('candidates.tests.next') }}</button>
                    <button v-if="currentGroup === maxQuestions" @click="sendAnswers(testAnswer.id, testAnswer.test_request_id, true)" class="btn general-btn col-6 col-md-5 col-lg-4 col-xl-3 mx-2 my-1 p-1">{{ $t('candidates.tests.sendAnswers') }}</button>
                  </div>
                </template>
              </AlternativeImageQuestion>
            </div>
            <!-- End -->
            <div class="py-3 buttons-container">
              <!-- Example buttons -->
              <button v-if="currentGroup === 0" @click="$router.push(`/candidates/test-detail/${testAnswer.test_request_id}`)" class="btn white-general-btn me-3">{{ $t('back') }}</button>
              <button v-if="currentGroup === 0" @click="startTest" class="btn general-btn">{{ $t('candidates.tests.startTest') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useCandidateTestsStore } from '@/stores/candidateTests.store'
import AlternativeImageQuestion from '@/components/tests/AlternativeImageQuestion.vue'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import { useTimer } from '@/use/useTimer'
import { useNavigationGuard } from '@/use/useNavigationGuard'
import TimeOverModal from '@/components/TimeOverModal.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const router = useRouter()
const route = useRoute()
const { updateAnswer, toggleTestStatus, toggleTimer, updateTimer } = useCandidateTestsStore()
const { testViewData, testAnswer, loading, testInProgress } = storeToRefs(useCandidateTestsStore())
const { fetchTestData } = useCandidateTestsStore()

/*
  Get test questions form and create empty answer.
*/

onBeforeMount(() => {
  fetchTestData(+route.params.id)
})

const maxQuestions = 36
const currentGroup = ref(0)
const alternatives = [
  { text: 'A', value: 'A' },
  { text: 'B', value: 'B' },
  { text: 'C', value: 'C' },
  { text: 'D', value: 'D' },
  { text: 'E', value: 'E' }
]
const answersForm = ref({})
const confirmSendAnswersDialog = createConfirmDialog(ConfirmationModal)

/*
  Navigation guard
*/

useNavigationGuard(testInProgress, () => {
  const params = {
    test_answer: {
      answer: answersForm.value,
      test_request_id: testAnswer.value?.test_request_id
    }
  }
  toggleTestStatus(false)
  toggleTimer(false)
  stopCounter()
  updateTimer('')
  updateAnswer(testAnswer.value?.id, params, true)
})

/*
  Start test
*/

const startTest = () => {
  // Move to test questions
  nextGroup('next')
  // Update store states
  toggleTestStatus(true)
  toggleTimer(true)
  // Start composable timer
  startCounter(maxTime)
}

/*
  Questions logic
*/

const nextGroup = (direction) => {
  switch (direction) {
    case 'next':
      currentGroup.value += 1
      break
    case 'back':
      currentGroup.value -= 1
      break
  }
}

/*
  Send answers
*/

const sendAnswers = async (testAnswerId, testRequestId, redirectionFlag) => {
  const params = {
    test_answer: {
      answer: answersForm.value,
      test_request_id: testRequestId
    }
  }
  if (redirectionFlag) {
    // Open confirmation modal
    const confirmation = await confirmSendAnswersDialog.reveal({
      icon: 'circle-info',
      title: t('candidates.tests.aboutToSendAnswers'),
      text: t('confirmAction'),
      confirmBtnText: t('candidates.tests.sendAnswers'),
      cancelBtnText: t('candidates.tests.backToTest')
    })
    // On confirm, change test status and update answer
    if (!confirmation.isCanceled) {
      toggleTestStatus(false)
      toggleTimer(false)
      stopCounter()
      updateTimer('')
      updateAnswer(testAnswerId, params, redirectionFlag)
    }
  } else {
    toggleTestStatus(false)
    toggleTimer(false)
    stopCounter()
    updateTimer('')
    updateAnswer(testAnswerId, params, redirectionFlag)
  }
}

/*
  Timer
*/

const maxTime = 360
const { startCounter, stopCounter, timeOver } = useTimer()

watch(() => timeOver.value, (newValue) => {
  if (newValue === true) {
    // On time over, send answers without redirection, hide timer and raise modal
    sendAnswers(testAnswer.value.id, testAnswer.value.test_request_id, false)
    // Close confirmation modal in case it was open
    confirmSendAnswersDialog.close()
    // Raise time over modal
    reveal()
  }
})

/*
  Timer over modal
*/

const { reveal, onConfirm } = createConfirmDialog(TimeOverModal)
onConfirm(() => {
  router.push({ name: 'candidatesFinishedTest' })
})

</script>

<style scoped>
  .group-width {
    width: 80%;
  }
  .buttons-container{
    text-align: center;
  }

  @media (max-width: 1199.98px) {
    .group-width {
      width: 100%;
    }
  }
</style>
