<template>
  <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-white shadow-sm">
    <div class="container-fluid">
      <img src="../../assets/img/castor-logo.png" alt="castor" class="logo ms-2">
      <!-- Test in progress -->
      <ul v-if="testInProgress" class="navbar-nav ms-auto mb-lg-0">
        <li class="nav-item">
          <div class="nav-link text-primary fw-bold me-5 pe-pointer" @click="openConfirmationModal">{{ $t('candidates.tests.abandonTest') }}</div>
        </li>
        <li v-if="showTimer" class="nav-item">
          <div class="text-primary fw-bold fs-4 me-5">
            <span class="me-2"><font-awesome-icon icon="clock" /></span>
            {{ timerCountFormatted }}
          </div>
        </li>
      </ul>

      <!-- Test not in progress -->
      <button
        v-if="!testInProgress"
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="visible=!visible"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div v-if="!testInProgress" class="navbar-collapse ms-2" :class="!visible ? 'collapse' : ''" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <RouterLink :to="candidatesTestsPath" class="nav-link text-primary fw-bold" :class="$route.path === candidatesTestsPath ? 'active' : ''" @click="visible=!visible">{{ $t('candidates.home.title') }}</RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink :to="candidatesPersonalInfoPath" class="nav-link text-primary fw-bold" :class="$route.path === candidatesPersonalInfoPath || $route.path === candidatesPasswordPath ? 'active' : ''" @click="visible=!visible">{{ $t('account.title') }}</RouterLink>
          </li>
          <li class="nav-item">
            <a class="nav-link text-primary fw-bold" role="button" @click="authStore.logout()">{{ $t('signOut') }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth.store'
import { useCandidateTestsStore } from '@/stores/candidateTests.store'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import { useI18n } from 'vue-i18n'

const authStore = useAuthStore()
const visible = ref(false)
const router = useRouter()
const { t } = useI18n()

const { testInProgress, showTimer, timerCountFormatted } = storeToRefs(useCandidateTestsStore())

/*
  Confirmation modal
*/

const confirmSendAnswersDialog = createConfirmDialog(ConfirmationModal)

const openConfirmationModal = async () => {
  const confirmation = await confirmSendAnswersDialog.reveal({
    icon: 'circle-info',
    title: t('candidates.tests.aboutToLeaveTest'),
    text: t('candidates.tests.confirmAbandonTest'),
    confirmBtnText: t('candidates.tests.abandon'),
    cancelBtnText: t('candidates.tests.backToTest')
  })
  if (!confirmation.isCanceled) {
    router.push({ name: 'candidatesFinishedTest' })
  }
}

/*
  Routes
*/

const candidatesTestsPath = '/candidates/tests'
const candidatesPersonalInfoPath = '/candidates/account/personal-info'
const candidatesPasswordPath = '/candidates/account/password'
</script>

<style scoped>
  .nav-link.active {
    text-decoration: underline;
  }
  .logo {
    width: 120px;
  }

  .pe-pointer:hover {
    cursor: pointer;
  }
</style>
