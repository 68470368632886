// import router from '@/router'
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetch-wrapper'
import { createToast } from 'mosha-vue-toastify'

import i18n from '../i18n'

const { t } = i18n().global

const handleError = (message) => {
  createToast(message, {
    hideProgressBar: true,
    type: 'danger',
    showIcon: true,
    position: 'bottom-right'
  })
}

export const useAnalystTestsStore = defineStore({
  id: 'analystTests',
  state: () => ({
    candidate: {},
    candidates: [],
    test: {},
    tests: [],
    testRequests: [],
    error: null,
    loading: false
  }),
  actions: {
    fetchCandidates () {
      this.error = null
      this.loading = true
      this.candidates = []
      fetchWrapper.get(`${process.env.VUE_APP_API_BASE}/analysts/candidates`)
        .then((response) => {
          this.candidates = response
        }).catch((error) => {
          this.error = error
          const message = t('genericError')
          handleError(message)
        }).finally(() => {
          this.loading = false
        })
    },
    fetchTests () {
      this.error = null
      this.loading = true
      this.tests = []
      fetchWrapper.get(`${process.env.VUE_APP_API_BASE}/analysts/tests`)
        .then((response) => {
          this.tests = response
        }).catch((error) => {
          this.error = error
          const message = t('genericError')
          handleError(message)
        }).finally(() => {
          this.loading = false
        })
    },
    fetchAllTestRequests () {
      this.error = null
      this.loading = true
      this.testRequests = []
      fetchWrapper.get(`${process.env.VUE_APP_API_BASE}/analysts/test_requests`)
        .then((response) => {
          this.testRequests = response
        }).catch((error) => {
          this.error = error
          const message = t('genericError')
          handleError(message)
        }).finally(() => {
          this.loading = false
        })
    },
    fetchTestRequestsFromCandidate (candidateId) {
      this.error = null
      this.loading = true
      this.candidate = {}
      this.testRequests = []
      fetchWrapper.get(`${process.env.VUE_APP_API_BASE}/analysts/candidates/${candidateId}`)
        .then((response) => {
          this.testRequests = response.test_requests
          this.candidate = {
            id: response.id,
            email: response.email,
            name: response.name,
            lastname: response.lastname
          }
        }).catch((error) => {
          this.error = error
          const message = t('genericError')
          handleError(message)
        }).finally(() => {
          this.loading = false
        })
    },
    fetchTestRequestsFromTest (testId) {
      this.error = null
      this.loading = true
      this.test = {}
      this.testRequests = []
      fetchWrapper.get(`${process.env.VUE_APP_API_BASE}/analysts/tests/${testId}`)
        .then((response) => {
          this.testRequests = response.test_requests
          this.test = response
        }).catch((error) => {
          this.error = error
          const message = t('genericError')
          handleError(message)
        }).finally(() => {
          this.loading = false
        })
    }
  }
})
