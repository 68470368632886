export default {
  genericError: 'Ocorreu um erro, por favor, tente novamente.',
  dataUpdatedSuccessfully: 'Os dados foram atualizados com sucesso.',
  passwordUpdatedSuccessfully: 'A senha foi atualizada com sucesso.',
  sorryAnErrorHasOcurred: 'Desculpe, ocorreu um erro',
  pageNotFound: 'Não conseguimos encontrar a página que você está procurando. Convidamos você a voltar para a página inicial.',
  backToHome: 'Voltar para a página inicial',
  signOut: 'Sair',
  back: 'Voltar',
  noSearchResults: 'Nenhum resultado encontrado para sua pesquisa',
  requiredField: 'Campo obrigatório',
  dragAndDropToUploadFile: 'Clique ou arraste seu arquivo para esta área para fazer o upload',
  maxFileSize: 'Tamanho máximo 2MB.',
  excelFormat: 'Formato Excel.',
  continue: 'Continuar',
  confirmAction: 'Você tem certeza de que deseja realizar esta ação?',

  login: {
    email: 'E-mail',
    password: 'Senha',
    signIn: 'Entrar',
    areYouClient: 'Você é um cliente?',
    areYouCandidate: 'Você é um candidato?',
    signInHere: 'Faça login aqui',
    forgotPassword: 'Esqueceu sua senha?',
    sendRecoveryEmail: 'Enviar e-mail de recuperação',
    invalidCredentials: 'Credenciais inválidas',
    recoveryEmailSentSuccessfully: 'E-mail de recuperação enviado com sucesso',
    nonExistentEmail: 'E-mail inexistente'
  },

  account: {
    title: 'Conta',
    accountData: 'Dados da conta',
    firstName: 'Nome',
    lastName: 'Sobrenome',
    language: 'Linguagem',
    updateData: 'Atualizar dados',
    changePassword: 'Alterar senha',
    changeOrUpdatePassword: 'Alterar ou atualizar senha',
    currentPassword: 'Senha atual',
    newPassword: 'Nova senha',
    passwordConfirmation: 'Repita a nova senha',
    passwordsDontMatch: 'As senhas não coincidem'
  },

  languages: {
    es: 'Espanhol',
    pt: 'Português'
  }
}
