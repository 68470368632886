<template>
   <div class="p-3">
    <div class="shadow-lg p-5 mx-auto my-5 rounded-3 login-card-width">
      <div class="text-center">
        <img src="../assets/img/castor-logo.png" alt="castor" class="w-100">
      </div>

      <form class="mt-5" @submit.prevent="submitSignIn">
        <div class="mb-3">
          <label for="email" class="form-label text-primary fw-bold">{{ $t('login.email') }}</label>
          <input
            type="email"
            placeholder="example@email.com"
            class="form-control"
            id="email"
            v-model="email"
            required
          />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label text-primary fw-bold">{{ $t('login.password') }}</label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="password"
            required
          />
        </div>
        <div class="d-grid gap-2 mt-5">
          <button class="general-btn rounded-pill p-3">{{ $t('login.signIn') }}</button>
        </div>
      </form>

      <div class="mt-5 text-center">
        <div v-if="userType === 'candidate'">
          {{ $t('login.areYouClient') }}
          <RouterLink to="/analysts/login">{{ $t('login.signInHere') }}</RouterLink>
        </div>
        <div v-if="userType === 'analyst'">
          {{ $t('login.areYouCandidate') }}
          <RouterLink to="/candidates/login">{{ $t('login.signInHere') }}</RouterLink>
        </div>
        <div>
          <div class="accordion accordion-flush mt-5" id="titleAccordion">
            <div class="accordion-item bg-transparent">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed d-block bg-transparent text-center"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  <a class="fw-500">{{ $t('login.forgotPassword') }}</a>
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                :class="props.showAccordion ? 'show' : ''"
                aria-labelledby="headingOne"
                data-bs-parent="#titleAccordion"
              >
                <div class="accordion-body">
                  <div class="row">
                    <form class="mt-2" @submit.prevent="submitRecoverAccount">
                      <div class="mb-3">
                        <label for="emailRecover" class="form-label text-primary fw-bold">{{ $t('login.email') }}</label>
                        <input
                          type="emailRecover"
                          placeholder="example@email.com"
                          class="form-control"
                          id="emailRecover"
                          v-model="emailRecover"
                          required
                        />
                      </div>
                      <div class="d-grid gap-2 mt-5">
                        <button class="general-btn rounded-pill p-3">{{ $t('login.sendRecoveryEmail') }}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import { useAuthStore } from '@/stores/auth.store'
import { sendRecoverAccountEmail } from '@/services/analysts/candidates'
import { createToast } from 'mosha-vue-toastify'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const authStore = useAuthStore()

const props = defineProps({
  userType: String
})

const email = ref('')
const password = ref('')
const emailRecover = ref('')

const submitSignIn = () => {
  return authStore.login(props.userType, email.value, password.value).catch(
    errors => {
      const message = t('login.invalidCredentials')
      createToast(message, {
        hideProgressBar: true,
        type: 'danger',
        showIcon: true,
        position: 'bottom-right'
      })
    }
  )
}

const submitRecoverAccount = () => {
  const userType = props.userType === 'analyst' ? 'analysts' : 'candidates'
  return sendRecoverAccountEmail(emailRecover.value, userType
  ).then(
    () => {
      const message = t('login.recoveryEmailSentSuccessfully')
      createToast(message, {
        hideProgressBar: true,
        type: 'success',
        showIcon: true,
        position: 'bottom-right'
      })
    }
  ).catch(
    errors => {
      const message = t('login.nonExistentEmail')
      createToast(message, {
        hideProgressBar: true,
        type: 'danger',
        showIcon: true,
        position: 'bottom-right'
      })
    }
  )
}
</script>

<style>
  .login-card-width {
    width: 30%;
  }

  @media (max-width: 1199.98px) {
    .login-card-width {
      width: 80%;
    }
  }
</style>
