import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetch-wrapper'
import { getUserTypePath } from '@/helpers/get-user-type-path'
import router from '@/router'

import { setI18nLanguage } from '../i18n'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem('user')),
    userType: localStorage.getItem('userType')
  }),
  actions: {
    async login (userType, email, password) {
      const params = {
        email: email,
        password: password
      }

      const userTypePath = getUserTypePath(userType)
      const url = `${process.env.VUE_APP_API_BASE}/${userTypePath}/sessions`

      const user = await fetchWrapper.post(url, params)
      // update pinia state
      this.user = { token: user.token }
      this.userType = userType
      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('userType', userType)
      localStorage.setItem('lang', user.language)
      setI18nLanguage(user.language)
      // redirect to '/'
      router.push(`/${userTypePath}`)
    },
    logout () {
      if (!this.user || !this.userType) return
      const userTypePath = getUserTypePath(this.userType)
      this.user = null
      this.userType = null
      localStorage.removeItem('user')
      localStorage.removeItem('userType')
      router.replace(`/${userTypePath}/login`)
    },
    authorizeWithToken (user, language) {
      const userType = user.type
      const userCredentials = user.credentials
      this.userType = userType
      this.user = user.credentials
      localStorage.setItem('user', JSON.stringify(userCredentials))
      localStorage.setItem('userType', userType)
      localStorage.setItem('lang', language)
      setI18nLanguage(language)
    }
  }
})
