<template>
  <div id="footer" class="pt-4 text-center">
    <a href="https://www.poluxhr.com" target="_blank"><img src="../../assets/img/by-polux.svg" alt="by-polux" style="width:123px;height:30px;"></a>
  </div>
</template>

<style scoped>
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5.5rem;            /* Footer height */
  align-items: center;
}
</style>
