<template>
  <DialogsWrapper v-if="!loading"/>
  <Loading v-model:active="loading" loader="dots"/>
  <div  v-if="!timeOver">
    <div class="container mt-5 bg-white shadow p-3">
      <div class="row">
        <div v-if="testViewData" class="col-sm-12">
          <div clas="row">
            <div v-if="currentQuestion < 3" class="col-sm-12">
              <h2 class="p-3 text-primary">{{ $t('candidates.tests.instructions') }}:</h2>
              <p class="px-3">{{ testViewData.instruction }}</p>
            </div>
            <div v-else class="col-sm-12">
              <p class="px-3"><b>{{ $t('candidates.tests.wonderlic.answerHonestly') }}</b></p>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-sm-12 px-4">
              <!-- Questions -->
              <div v-for="(question, qIndex) in testViewData.questions" :key="qIndex">
                <div v-if="currentQuestion === qIndex">
                  <!-- Single choice questions -->
                  <TextAlternativeQuestion
                    v-if="question.type === 'single-choice'"
                    :question="question"
                    :gIndex="qIndex"
                    :alternatives="makeAlternatives(question.choices)"
                    :questionNumber="question.is_example ? qIndex + 1 : qIndex - 2"
                    :answersForm="question.is_example ? ref({}) : answersForm"
                    :isExample="question.is_example"
                  >
                    <template v-slot:example>
                      <div v-if="question.is_example" class="row">
                        <div class="col-sm-12">
                          <p><b>{{ $t('candidates.tests.wonderlic.exampleQuestion') }}: {{ qIndex + 1 }}</b></p>
                          <p>{{ question.explanation }}</p>
                        </div>
                      </div>
                    </template>
                  </TextAlternativeQuestion>

                  <!-- Multiple choice questions -->
                  <MultipleChoiceQuestion
                    v-if="question.type === 'multiple-choice'"
                    :question="question"
                    :gIndex="qIndex"
                    :alternatives="makeAlternatives(question.choices)"
                    :currentQuestion="currentQuestion"
                    :questionNumber="question.is_example ? qIndex + 1 : qIndex - 2"
                    :answersForm="question.is_example ? exampleAnswersForm : answersForm"
                    :maxExpectedAnswers="question.expected_answers"
                    :isExample="question.is_example"
                    @updateAnswersForm="updateAnswersForm"
                  >
                    <template v-slot:header>
                      <div v-if="question.is_example" class="row">
                        <div class="col-sm-12">
                          <p><b>{{ $t('candidates.tests.wonderlic.exampleQuestion') }}: {{ qIndex + 1 }}</b></p>
                          <p>{{ question.explanation }}</p>
                        </div>
                      </div>
                    </template>
                  </MultipleChoiceQuestion>

                  <!-- Text questions -->
                  <TextQuestion
                    v-if="question.type === 'text'"
                    :question="question"
                    :gIndex="qIndex"
                    :currentQuestion="currentQuestion"
                    :questionNumber="question.is_example ? qIndex + 1 : qIndex - 2"
                    :answersForm="question.is_example ? exampleAnswersForm : answersForm"
                    :isExample="question.is_example"
                  >
                    <template v-slot:header>
                      <div v-if="question.is_example" class="row">
                        <div class="col-sm-12">
                          <p><b>{{ $t('candidates.tests.wonderlic.exampleQuestion') }}: {{ qIndex + 1 }}</b></p>
                          <p>{{ question.explanation }}</p>
                        </div>
                      </div>
                    </template>
                  </TextQuestion>

                  <!-- Numeric questions -->
                  <NumericQuestion
                    v-if="question.type === 'numeric'"
                    :question="question"
                    :gIndex="qIndex"
                    :currentQuestion="currentQuestion"
                    :expectedAnswers="question.expected_answers"
                    :questionNumber="question.is_example ? qIndex + 1 : qIndex - 2"
                    :answersForm="question.is_example ? exampleAnswersForm : answersForm"
                    @updateAnswersForm="updateAnswersForm"
                    :isExample="question.is_example"
                  >
                    <template v-slot:header>
                      <div v-if="question.is_example" class="row">
                        <div class="col-sm-12">
                          <p><b>{{ $t('candidates.tests.wonderlic.exampleQuestion') }}: {{ qIndex + 1 }}</b></p>
                          <p>{{ question.explanation }}</p>
                        </div>
                      </div>
                    </template>
                    <template v-slot:footer>
                      <div v-if="question.illustration_path" class="row text-center">
                        <div class="col-lg-4 col-sm-12 mx-auto">
                          <v-lazy-image :src="question.illustration_path" class="w-100"/>
                        </div>
                      </div>
                    </template>
                  </NumericQuestion>
                </div>
              </div>
            </div>
          </div>
          <!-- Buttons -->
          <div class="row">
            <div class="col-sm-12">
              <div class="py-3 buttons-container d-flex justify-content-end">
                <button v-if="currentQuestion" @click="nextQuestion('back')" class="btn white-general-btn">{{ $t('candidates.tests.previous') }}</button>
                <button v-if="currentQuestion != 2 && currentQuestion < testViewData.questions.length - 1" @click="nextQuestion('next')" class="btn white-general-btn mx-3">{{ $t('candidates.tests.next') }}</button>
                <button v-if="currentQuestion === 2" @click="startTest" class="btn general-btn mx-3">{{ $t('candidates.tests.startTest') }}</button>
                <button v-if="currentQuestion === testViewData.questions.length - 1" @click="sendAnswers(testAnswer.id, testAnswer.test_request_id, true)" class="btn general-btn mx-3">{{ $t('candidates.tests.sendAnswers') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, reactive, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useCandidateTestsStore } from '@/stores/candidateTests.store'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import { useTimer } from '@/use/useTimer'
import { useNavigationGuard } from '@/use/useNavigationGuard'
import VLazyImage from 'v-lazy-image'
import TimeOverModal from '@/components/TimeOverModal.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import Loading from 'vue-loading-overlay'
import TextAlternativeQuestion from '@/components/tests/TextAlternativeQuestion.vue'
import MultipleChoiceQuestion from '@/components/tests/MultipleChoiceQuestion.vue'
import TextQuestion from '@/components/tests/TextQuestion.vue'
import NumericQuestion from '@/components/tests/NumericQuestion.vue'
import 'vue-loading-overlay/dist/vue-loading.css'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const router = useRouter()
const route = useRoute()
const { updateAnswer, toggleTestStatus, toggleTimer, updateTimer } = useCandidateTestsStore()
const { testViewData, testAnswer, loading, testInProgress } = storeToRefs(useCandidateTestsStore())
const { fetchTestData } = useCandidateTestsStore()

/*
  Get test questions form and create empty answer.
*/

onBeforeMount(() => {
  fetchTestData(+route.params.id)
})

const exampleAnswersForm = ref({})
const confirmSendAnswersDialog = createConfirmDialog(ConfirmationModal)
const currentQuestion = ref(0)
const answersForm = reactive({
  1: '',
  2: '',
  3: '',
  4: '',
  5: '',
  6: '',
  7: '',
  8: '',
  9: '',
  10: '',
  11: '',
  12: '',
  13: '',
  14: '',
  15: [],
  16: '',
  17: '',
  18: '',
  19: '',
  20: '',
  21: '',
  22: '',
  23: '',
  24: [],
  25: '',
  26: [],
  27: '',
  28: '',
  29: '',
  30: '',
  31: '',
  32: '',
  33: '',
  34: '',
  35: '',
  36: '',
  37: [],
  38: '',
  39: '',
  40: '',
  41: '',
  42: '',
  43: '',
  44: [],
  45: '',
  46: [],
  47: '',
  48: '',
  49: '',
  50: ''
})

/*
  Navigation guard
*/

useNavigationGuard(testInProgress, () => {
  const params = {
    test_answer: {
      answer: answersForm.value,
      test_request_id: testAnswer.value?.test_request_id
    }
  }
  toggleTestStatus(false)
  toggleTimer(false)
  stopCounter()
  updateTimer('')
  updateAnswer(testAnswer.value?.id, params, true)
})

/*
  Start test
*/

const startTest = () => {
  // Move to test questions
  nextQuestion('next')
  // Update store states
  toggleTestStatus(true)
  toggleTimer(true)
  // Start composable timer
  startCounter(maxTime)
}

/*
  Questions logic
*/

const updateAnswersForm = (questionId, value) => {
  answersForm[questionId] = value
}

const nextQuestion = (direction) => {
  switch (direction) {
    case 'next':
      currentQuestion.value += 1
      break
    case 'back':
      currentQuestion.value -= 1
      break
  }
}

const makeAlternatives = (choices) => {
  const alternatives = []
  Object.keys(choices).forEach(function (key) {
    const alternative = {
      text: choices[key],
      value: key
    }
    alternatives.push(alternative)
  })
  return alternatives
}

/*
  Send answers
*/

const sendAnswers = async (testAnswerId, testRequestId, redirectionFlag) => {
  const params = {
    test_answer: {
      answer: answersForm,
      test_request_id: testRequestId
    }
  }
  if (redirectionFlag) {
    // Open confirmation modal
    const confirmation = await confirmSendAnswersDialog.reveal({
      icon: 'circle-info',
      title: t('candidates.tests.aboutToSendAnswers'),
      text: t('confirmAction'),
      confirmBtnText: t('candidates.tests.sendAnswers'),
      cancelBtnText: t('candidates.tests.backToTest')
    })
    // On confirm, change test status and update answer
    if (!confirmation.isCanceled) {
      toggleTestStatus(false)
      toggleTimer(false)
      stopCounter()
      updateTimer('')
      updateAnswer(testAnswerId, params, redirectionFlag)
    }
  } else {
    toggleTestStatus(false)
    toggleTimer(false)
    stopCounter()
    updateTimer('')
    updateAnswer(testAnswerId, params, redirectionFlag)
  }
}

/*
  Timer
*/

const maxTime = 720 // 12 minutes
const { startCounter, stopCounter, timeOver } = useTimer()

watch(() => timeOver.value, (newValue) => {
  if (newValue === true) {
    // On time over, send answers without redirection, hide timer and raise modal
    sendAnswers(testAnswer.value.id, testAnswer.value.test_request_id, false)
    // Close confirmation modal in case it was open
    confirmSendAnswersDialog.close()
    // Raise time over modal
    reveal()
  }
})

/*
  Timer over modal
*/

const { reveal, onConfirm } = createConfirmDialog(TimeOverModal)
onConfirm(() => {
  router.push({ name: 'candidatesFinishedTest' })
})

</script>

<style scoped>
  .group-width {
    width: 80%;
  }
  .buttons-container{
    text-align: center;
  }

  @media (max-width: 1199.98px) {
    .group-width {
      width: 100%;
    }
  }
</style>
