<template>
  <div>
    <slot name="example"></slot>
    <div class="row">
      <div v-if="props.questionNumber" class="col-sm-10 mb-2">
        <b class="badge bg-primary">{{ props.questionNumber }}</b>
      </div>
      <div class="statement-box col-sm-10 offset-sm-1 fw-bold">
        {{ question.statement }}
      </div>
      <div class="col-sm-10 offset-sm-1 mt-2">
        <div v-for="(alternative, gIndex) in props.alternatives" :key="gIndex">
          <div v-if="props.isExample" class="form-check">
            <input
              type="radio"
              :name="`example-${props.questionNumber}`"
              :id="`question-${question.id}-${alternative.value}`"
              class="form-check-input">
            <label :for="`question-${question.id}-${alternative.value}`" class="ms-2 form-check-label">{{ alternative.text }}</label>
          </div>
          <div v-else class="form-check">
            <input
              type="radio"
              v-model="answers[question.id]"
              :value="alternative.value"
              :id="`question-${question.id}-${alternative.value}`"
              class="form-check-input">
            <label :for="`question-${question.id}-${alternative.value}`" class="ms-2 form-check-label">{{ alternative.text }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'

const props = defineProps({
  question: Object,
  questionNumber: Number,
  alternatives: Array,
  answersForm: Object,
  isExample: Boolean
})

const answers = ref(props.answersForm)
</script>

<style scoped lang="scss">
  .statement-box {
    white-space:pre-wrap;
  }
</style>
