<template>
  <DialogsWrapper v-if="!loading"/>
  <Loading v-model:active="loading" loader="dots"/>
  <div>
    <div v-if="testViewData" class="container mt-5 bg-white shadow p-3">
      <!-- Instructions -->
      <div class="p-3 fw-bold">
        <div>
          {{ testViewData.instruction }}
        </div>
      </div>
      <!-- Warnings -->
      <div v-if="warnings.blankOption" class="pt-3 ps-3">
        <p class="text-danger mb-0 py-1 fw-500">{{ $t('candidates.tests.mustChooseAnAlternative') }}</p>
      </div>
      <hr>
      <!-- Test -->
      <div class="p-3 test-container">
        <!-- First example -->
        <div v-if="currentQuestion === 0">
          <TextAlternativeQuestion
          :question="testViewData.examples[0]"
          :alternatives="[
            { text: testViewData.examples[0].choices['A'], value: 'A' },
            { text: testViewData.examples[0].choices['B'], value: 'B' },
            { text: testViewData.examples[0].choices['C'], value: 'C' }
          ]"
          :answersForm="answersForm"
          :isExample="true"
          >
            <template v-slot:example>
              <h4 class="mb-1 text-primary">I. Multiple choice</h4>
              <h5 class="mb-4 text-primary">Please choose the best question or response for the blank</h5>
              <p class="mb-4">Example:</p>
            </template>
          </TextAlternativeQuestion>
        </div>

        <!--Questions Part I -->
        <div v-for="gIndex in secondGroup" :key="gIndex">
          <TextAlternativeQuestion
            v-if="currentQuestion === gIndex"
            :question="testViewData.questions[gIndex-1]"
            :gIndex="gIndex"
            :alternatives="[
              { text: testViewData.questions[gIndex-1].choices['A'], value: 'A' },
              { text: testViewData.questions[gIndex-1].choices['B'], value: 'B' },
              { text: testViewData.questions[gIndex-1].choices['C'], value: 'C' }
            ]"
            :answersForm="answersForm"
            :questionNumber="currentQuestion"
          />
        </div>

        <!-- Second example -->
        <div v-if="currentQuestion === 16">
          <TextAlternativeQuestion
          :question="testViewData.examples[1]"
          :alternatives="[
            { text: testViewData.examples[1].choices['A'], value: 'A' },
            { text: testViewData.examples[1].choices['B'], value: 'B' },
            { text: testViewData.examples[1].choices['C'], value: 'C' }
          ]"
          :answersForm="answersForm"
          :isExample="true"
          >
            <template v-slot:example>
              <h4 class="mb-1 text-primary">II. Multiple choice</h4>
              <h5 class="mb-4 text-primary">Please choose the correct form</h5>
              <p class="mb-4">Example:</p>
            </template>
          </TextAlternativeQuestion>
        </div>

        <!-- Questions Part II -->
        <div v-for="sIndex in maxQuestions" :key="sIndex + secondGroup">
          <TextAlternativeQuestion
            v-if="currentQuestion === sIndex + secondGroup + 1"
            :question="testViewData.questions[sIndex + secondGroup - 1]"
            :sIndex="sIndex + secondGroup"
            :alternatives="[
              { text: testViewData.questions[sIndex + secondGroup - 1].choices['A'], value: 'A' },
              { text: testViewData.questions[sIndex + secondGroup - 1].choices['B'], value: 'B' },
              { text: testViewData.questions[sIndex + secondGroup - 1].choices['C'], value: 'C' }
            ]"
            :answersForm="answersForm"
            :questionNumber="currentQuestion - 1"
          />
        </div>

        <!-- End -->
        <div class="py-3 buttons-container">
          <button v-if="currentQuestion === 0" @click="nextQuestion('next')" class="btn white-general-btn my-1 mx-2">{{ $t('candidates.tests.startPartOne') }}</button>
          <button v-if="currentQuestion !== 0" @click="nextQuestion('back')" class="btn white-general-btn my-1 mx-2">{{ $t('candidates.tests.previous') }}</button>
          <button v-if="currentQuestion === 16" @click="nextQuestion('next')" class="btn white-general-btn my-1 mx-2">{{ $t('candidates.tests.startPartTwo') }}</button>
          <button v-if="currentQuestion < maxQuestions && currentQuestion !== 0 && currentQuestion !== 16" @click="nextQuestion('next')" class="btn white-general-btn my-1 mx-2">{{ $t('candidates.tests.next') }}</button>
          <button v-if="currentQuestion === maxQuestions" @click="sendAnswers(testAnswer.id, testAnswer.test_request_id)" class="btn general-btn my-1 mx-2">{{ $t('candidates.tests.sendAnswers') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useCandidateTestsStore } from '@/stores/candidateTests.store'
import TextAlternativeQuestion from '@/components/tests/TextAlternativeQuestion.vue'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import Loading from 'vue-loading-overlay'
import { useNavigationGuard } from '@/use/useNavigationGuard'
import 'vue-loading-overlay/dist/vue-loading.css'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { updateAnswer, toggleTestStatus, fetchTestData } = useCandidateTestsStore()
const { testViewData, testAnswer, loading, testInProgress } = storeToRefs(useCandidateTestsStore())

const warnings = reactive({
  blankOption: false
})
const secondGroup = 15
const maxQuestions = 36 // Has one example
const currentQuestion = ref(0)
const answersForm = ref({})

const confirmSendAnswersDialog = createConfirmDialog(ConfirmationModal)
const route = useRoute()

/*
  Get test questions form and create empty answer.
*/

onBeforeMount(() => {
  fetchTestData(+route.params.id)
})

/*
  Test in progress
*/

onMounted(() => {
  toggleTestStatus(true)
})

/*
  Questions logic
*/

const nextQuestion = (direction) => {
  switch (direction) {
    case 'next':
      if (validateQuestionInputs(currentQuestion.value)) {
        currentQuestion.value += 1
        warnings.blankOption = false
      } else {
        warnings.blankOption = true
      }
      break
    case 'back':
      currentQuestion.value -= 1
      warnings.blankOption = false
      break
  }
}

const validateQuestionInputs = (questionIndex) => {
  if (currentQuestion.value === 16 || currentQuestion.value === 0) return true

  const auxQuestionIndex = questionIndex <= secondGroup ? questionIndex : questionIndex - 1
  return answersForm.value[auxQuestionIndex] !== undefined
}

const validateAllInputs = () => {
  let valid = true
  for (let question = 1; question <= maxQuestions; question++) {
    const validQuestion = validateQuestionInputs(question)
    valid = valid && validQuestion
  }
  return valid
}

/*
  Send answers
*/

const sendAnswers = async (testAnswerId, testRequestId) => {
  const params = {
    test_answer: {
      answer: answersForm.value,
      test_request_id: testRequestId
    }
  }
  if (validateAllInputs()) {
    // Open confirmation modal
    const confirmation = await confirmSendAnswersDialog.reveal({
      icon: 'circle-info',
      title: t('candidates.tests.aboutToSendAnswers'),
      text: t('confirmAction'),
      confirmBtnText: t('candidates.tests.sendAnswers'),
      cancelBtnText: t('candidates.tests.backToTest')
    })
    // On confirm, change test status and update answer
    if (!confirmation.isCanceled) {
      toggleTestStatus(false)
      updateAnswer(testAnswerId, params, true)
    }
  }
}

/*
  Navigation guard
*/

useNavigationGuard(testInProgress, () => {
  const params = {
    test_answer: {
      answer: answersForm.value,
      test_request_id: testAnswer.value?.test_request_id
    }
  }
  toggleTestStatus(false)
  updateAnswer(testAnswer.value?.id, params, true)
})
</script>

<style scoped>
  .test-container {
    width: 50%;
  }
  .buttons-container {
    text-align: end;
  }

  @media (max-width: 1199.98px) {
    .test-container {
      width: 100%;
    }
    .buttons-container {
    text-align: center;
  }

  }
</style>
