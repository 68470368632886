<template>
   <div class="form-group has-search search-bar-w">
      <span class="form-control-feedback"><font-awesome-icon icon="magnifying-glass" /></span>
      <input @keyup="emitUpdateSearchInput" v-model="searchInput" type="text" class="form-control" :placeholder="props.placeholder" aria-label="Search">
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  placeholder: String
})

const searchInput = ref('')

const emit = defineEmits(['update:modelValue'])

const emitUpdateSearchInput = () => {
  emit('update:modelValue', searchInput.value)
}
</script>

<style scoped>
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

@media (max-width: 767.98px) {
  .search-bar-w {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1399.98px)  {
  .search-bar-w {
    width: 50%;
  }
}

@media (min-width: 1400px) {
  .search-bar-w {
    width: 25%;
  }
}

</style>
