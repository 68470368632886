<template>
  <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-white shadow-sm">
  <div class="container-fluid">
    <img src="../../assets/img/castor-logo.png" alt="castor" class="logo ms-2">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      @click="visible=!visible"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse" :class="!visible ? 'collapse' : ''" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <RouterLink :to="analystsRequestsPath" class="nav-link text-primary fw-bold" :class="$route.path === analystsRequestsPath ? 'active' : ''" @click="visible=!visible">{{ $t('analysts.requests.title') }}</RouterLink>
        </li>
        <li class="nav-item">
          <RouterLink :to="analystsTestsPath" class="nav-link text-primary fw-bold" :class="$route.path === analystsTestsPath ? 'active' : ''" @click="visible=!visible">{{ $t('analysts.tests.title') }}</RouterLink>
        </li>
        <li class="nav-item">
          <RouterLink :to="analystsCandidatesPath" class="nav-link text-primary fw-bold" :class="$route.path === analystsCandidatesPath ? 'active' : ''" @click="visible=!visible">{{ $t('analysts.candidates.title') }}</RouterLink>
        </li>
        <li class="nav-item">
          <RouterLink :to="analystPersonalInfoPath" class="nav-link text-primary fw-bold" :class="$route.path === analystPersonalInfoPath || $route.path === analystPasswordPath ? 'active' : ''" @click="visible=!visible">{{ $t('account.title') }}</RouterLink>
        </li>
        <li class="nav-item">
          <a class="nav-link text-primary fw-bold" role="button" @click="authStore.logout()">{{ $t('signOut') }}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script setup>
import { ref } from 'vue'
import { useAuthStore } from '@/stores/auth.store'

const authStore = useAuthStore()
const visible = ref(false)

const analystsRequestsPath = '/analysts/requests'
const analystsTestsPath = '/analysts/tests'
const analystsCandidatesPath = '/analysts/candidates'
const analystPersonalInfoPath = '/analysts/account/personal-info'
const analystPasswordPath = '/analysts/account/password'

</script>

<style scoped>
  .nav-link.active {
    text-decoration: underline;
  }
  .logo {
    width: 120px;
  }
</style>
