<template>
  <DialogsWrapper v-if="!loading"/>
  <Loading v-model:active="loading" loader="dots"/>
  <div v-if="!timeOver">
    <div class="container">
      <div class="row">
        <div v-if="testViewData" class="col-sm-12 bg-white py-3 px-4 mt-4">
          <div class="row">
            <div class="col-sm-6 w-50">
              <div class="p-3 text-primary fw-bold instructions-title">{{ $t('candidates.tests.instructions') }}:</div>
            </div>
          </div>
          <ol class="instructions ps-3">
            <li v-for="(instruction, index) in testViewData.instructions" :key="index">
              {{ instruction }}
            </li>
          </ol>
          <hr>
          <table class="table">
            <thead>
              <tr>
                <th scope="col-xs-2">{{ $t('candidates.tests.complexInstructions.insuredAmount') }}</th>
                <th scope="col-xs-3">{{ $t('candidates.tests.complexInstructions.insuranceClass') }}</th>
                <th scope="col-xs-4">{{ $t('candidates.tests.complexInstructions.date') }}</th>
                <th scope="col-xs-1">1</th>
                <th scope="col-xs-1">2</th>
                <th scope="col-xs-1">3</th>
              </tr>
            </thead>
            <tbody class="">
              <tr scope="row" v-for="questionData in testViewData.questions" :key="questionData.id">
                <td class="col-xs-2">{{ questionData.insured_amount }}</td>
                <td class="col-xs-3">{{ questionData.insurance_class }}</td>
                <td class="col-xs-4">{{ questionData.date }}</td>
                <td class="col-xs-1">
                  <input
                    type="checkbox"
                    v-model="answersForm[`${questionData.id}-1`]">
                </td>
                <td class="col-xs-1">
                  <input
                  type="checkbox"
                  v-model="answersForm[`${questionData.id}-2`]">
                </td>
                <td class="col-xs-1">
                  <input
                  type="checkbox"
                  v-model="answersForm[`${questionData.id}-3`]">
                </td>
              </tr>
            </tbody>
          </table>
          <button @click="sendAnswers(testAnswer.id, testAnswer.test_request_id, true)" class="btn general-btn float-end mt-2">{{ $t('candidates.tests.sendAnswers') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useTimer } from '@/use/useTimer'
import { useNavigationGuard } from '@/use/useNavigationGuard'
import { useCandidateTestsStore } from '@/stores/candidateTests.store'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import TimeOverModal from '@/components/TimeOverModal.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const router = useRouter()
const route = useRoute()
const { updateAnswer, toggleTestStatus, toggleTimer, updateTimer } = useCandidateTestsStore()
const { testAnswer, testViewData, loading, testInProgress } = storeToRefs(useCandidateTestsStore())
const { fetchTestData } = useCandidateTestsStore()

/*
  Get test questions form and create empty answer.
*/

onBeforeMount(() => {
  fetchTestData(+route.params.id)
})

const answersForm = ref({})

const confirmSendAnswersDialog = createConfirmDialog(ConfirmationModal)

/*
  Send answers
*/

const sendAnswers = async (testAnswerId, testRequestId, redirectionFlag) => {
  const params = {
    test_answer: {
      answer: answersForm.value,
      test_request_id: testRequestId
    }
  }
  // Redirection flag means the candidate clicked send answer button
  // Else means time is over so answers need to be sent
  if (redirectionFlag) {
    // Open confirmation modal
    const confirmation = await confirmSendAnswersDialog.reveal({
      icon: 'circle-info',
      title: t('candidates.tests.aboutToSendAnswers'),
      text: t('confirmAction'),
      confirmBtnText: t('candidates.tests.sendAnswers'),
      cancelBtnText: t('candidates.tests.backToTest')
    })
    // On confirm, change test status and update answer
    if (!confirmation.isCanceled) {
      toggleTestStatus(false)
      toggleTimer(false)
      stopCounter()
      updateTimer('')
      updateAnswer(testAnswerId, params, redirectionFlag)
    }
  } else {
    toggleTestStatus(false)
    toggleTimer(false)
    stopCounter()
    updateTimer('')
    updateAnswer(testAnswerId, params, redirectionFlag)
  }
}

/*
  Navigation guard
*/

useNavigationGuard(testInProgress, () => {
  const params = {
    test_answer: {
      answer: answersForm.value,
      test_request_id: testAnswer.value?.test_request_id
    }
  }
  toggleTestStatus(false)
  toggleTimer(false)
  stopCounter()
  updateTimer('')
  updateAnswer(testAnswer.value?.id, params, true)
})

/*
  Timer
*/

const maxTime = 450
const { startCounter, stopCounter, timeOver } = useTimer()

onMounted(() => {
  // Update store states
  toggleTestStatus(true)
})

watch(() => testViewData.value, () => {
  if (testViewData.value) {
    toggleTimer(true)
    // Start composable timer
    startCounter(maxTime)
  } else {
    toggleTimer(false)
    stopCounter()
    updateTimer('')
  }
})

watch(() => timeOver.value, (newValue) => {
  if (newValue === true) {
    // On time over send answers without redirection
    sendAnswers(testAnswer.value.id, testAnswer.value.test_request_id, false)
    // Close confirmation modal in case it was open
    confirmSendAnswersDialog.close()
    // Raise time over modal
    reveal()
  }
})

/*
  Timer over modal
*/

const { reveal, onConfirm } = createConfirmDialog(TimeOverModal)
onConfirm(() => {
  router.push({ name: 'candidatesFinishedTest' })
})
</script>

<style lang="scss" scoped>

  // To scroll only the table body

  table {
    border-collapse: collapse;
    width: 100%;
  }

  table {
    display: flex;
    flex-flow: column;
    width: 100%;
  }

  thead {
    flex: 0 0 auto;
  }

  tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
  }

  tr {
    width: 100%;
    table-layout: fixed;
  }

  // To always show the scroll of the table

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  // To change fontsize, table height and display depending on screen size

  // Phones
  @media (max-width: 767.98px) {
    .instructions-title {
      font-size: 16px;
    }
    .instructions {
      text-align: justify;
      font-size: 12px;
    }
    table {
      height: 200px;
      font-size: 12px;
    }
  }

  // Tablets
  @media (min-width: 768px) and (max-width: 1399.98px)  {
    .instructions-title {
      font-size: 18px;
    }
    .instructions {
      text-align: justify;
      font-size: 16px;
    }
    table {
      height: 300px;
      font-size: 16px;
    }
    tr {
      display: table;
    }
  }

  // Web
  @media (min-width: 1400px) {
    .instructions-title {
      font-size: 20px;
    }
    .instructions {
      text-align: justify;
      font-size: 18px;
    }
    table {
      height: 350px;
      font-size: 18px;
    }
    tr {
      display: table;
    }
  }
</style>
