<template>
  <div class="mx-auto mb-5 rounded-8 w-100">
    <!-- Web -->
    <div class="row" v-if="!$isMobile()">
      <slot name="example"></slot>
      <div class="col pt-5">
        <!-- Timer -->
        <slot name="timer"></slot>
        <div v-if="props.showQuestionNumber" class="col-sm-10 mb-2">
          <b class="badge bg-primary">{{ props.questionNumber }}</b>
        </div>
        <div class="statement-box col-sm-10 offset-sm-1 fw-bold">
          {{ question.statement }}
        </div>
        <!-- Alternatives -->
        <div class="text-start col-sm-10 offset-sm-1 mb-4">
          <div v-for="(alternative, gIndex) in props.alternatives" :key="gIndex">
            <div v-if="props.isExample">
              <input
                type="radio"
                :name="question.id"
                :id="`question-${question.id}-${alternative.value}`">
              <label :for="`question-${question.id}-${alternative.value}`" class="ms-2">{{ alternative.text }}</label>
            </div>
            <div v-else class="alternative-div">
              <input
                type="radio"
                :name="question.id"
                v-model="answers[question.id]"
                :value="alternative.value"
                :id="`question-${question.id}-${alternative.value}`">
              <label :for="`question-${question.id}-${alternative.value}`" class="ms-2">{{ alternative.text }}</label>
            </div>
          </div>
        </div>
        <!-- Buttons -->
        <slot name="buttons"></slot>
      </div>
      <div class="col">
        <v-lazy-image :src="imagePath" />
      </div>
    </div>

    <!-- Mobile -->
    <div class="row" v-else>
      <slot name="example"></slot>
      <div class="col">
        <!-- Timer -->
        <div class="text-center">
          <slot name="timer"></slot>
        </div>
        <div v-if="props.showQuestionNumber" class="col-sm-10 mb-2">
          <b class="badge bg-primary">{{ props.questionNumber }}</b>
        </div>
        <!-- Image and alternatives-->
        <div>
          <v-lazy-image :src="imagePath"/>
          <div class="text-center pt-3 mb-2">
            <div v-for="(alternative, gIndex) in props.alternatives" :key="gIndex">
              <div v-if="props.isExample">
                <input
                  type="radio"
                  :name="question.id"
                  :id="`question-${question.id}-${alternative.value}`">
                <label :for="`question-${question.id}-${alternative.value}`" class="ms-2">{{ alternative.text }}</label>
              </div>
              <div v-else class="alternative-div">
                <input
                  type="radio"
                  :name="question.id"
                  v-model="answers[question.id]"
                  :value="alternative.value"
                  :id="`question-${question.id}-${alternative.value}`">
                <label :for="`question-${question.id}-${alternative.value}`" class="ms-2">{{ alternative.text }}</label>
              </div>
            </div>
          </div>
          <!-- Buttons -->
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import VLazyImage from 'v-lazy-image'
import { ref, defineProps } from 'vue'

const props = defineProps({
  questionNumber: Number,
  question: Object,
  alternatives: Array,
  testName: String,
  answersForm: Object,
  isExample: Boolean,
  showQuestionNumber: Boolean
})
const answers = ref(props.answersForm)

let imagePath = `/files/${props.testName}/${props.questionNumber}.png`
if (!props.questionNumber) {
  imagePath = `/files/${props.testName}/example.png`
}

</script>

<style scoped lang="scss">
  img {
    width: 100%;
  }

  .statement-box {
    white-space:pre-wrap;
  }
</style>
