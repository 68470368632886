<template>
  <div class="container">
    <Loading v-model:active="loading" loader="dots" />
    <div class="shadow-lg p-5 mx-auto my-5 rounded-3 w-md-75">
      <div class="row">
        <div class="col-md-12">
          <h1>{{ $t('account.title') }}</h1>
          <br />
          <h4>{{ $t('account.accountData') }}</h4>
        </div>
      </div>
      <form class="mt-3" @submit.prevent="requestUpdatePersonalInfo">
        <div class="row">
          <div class="col-md-6">
            <!-- Name -->
            <div class="mb-3">
              <label for="name" class="form-label text-primary fw-bold">{{ $t('account.firstName') }}</label>
              <input
                type="text"
                :placeholder="$t('account.firstName')"
                class="form-control"
                id="name"
                v-model="formData.name"
                required
              />
            </div>
            <!-- Email (disabled) -->
            <div class="mb-3">
              <label for="email" class="form-label text-primary fw-bold">{{ $t('login.email') }}</label>
              <input
                type="email"
                disabled="true"
                class="form-control"
                id="email"
                v-model="formData.email"
              />
            </div>
          </div>
          <div class="col-md-6">
            <!-- Last name -->
            <div class="mb-3">
              <label for="lastname" class="form-label text-primary fw-bold"
                >{{ $t('account.lastName') }}</label
              >
              <input
                type="text"
                :placeholder="$t('account.lastName')"
                class="form-control"
                id="lastname"
                v-model="lastname"
                required
              />
            </div>
            <!-- Language -->
            <div class="mb-3">
              <label for="language" class="form-label text-primary fw-bold"
                >{{ $t('account.language') }}</label
              >
              <select name="language" id="language" class="form-select" v-model="language">
                <option v-for="locale in SUPPORT_LOCALES" :key="locale" :value="locale">
                  {{ $t(`languages.${locale}`) }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12 col-lg-6">
            <div class="row text-center">
              <div class="col-md-6 mt-3">
                <button class="general-btn rounded-pill">{{ $t('account.updateData') }}</button>
              </div>
              <div class="col-md-6 mt-3">
                <RouterLink
                  :to="`/candidates/account/password`"
                  class="btn white-general-btn px-3"
                  >{{ $t('account.changePassword') }}</RouterLink
                >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, reactive } from 'vue'
import { useCandidateStore } from '@/stores/candidate.store'
import { storeToRefs } from 'pinia'
import Loading from 'vue-loading-overlay'

import { SUPPORT_LOCALES } from '@/i18n'

const { fetchPersonalInfo, updatePersonalInfo } = useCandidateStore()
const { name, lastname, email, language, candidateId, loading } = storeToRefs(useCandidateStore())

onBeforeMount(() => {
  fetchPersonalInfo()
})

const formData = reactive({
  id: candidateId,
  name: name,
  lastname: lastname,
  email: email,
  language: language
})

const requestUpdatePersonalInfo = () => {
  const params = {
    candidate: {
      name: formData.name,
      lastname: formData.lastname,
      language: formData.language
    }
  }
  updatePersonalInfo(formData.id, params)
}
</script>

<style lang="scss" scoped>
.w-md-75 {
  width: 75%;
}

@media (max-width: 768px) {
  .w-md-75 {
    width: 100%;
  }
}
</style>
