import { computed, ref } from 'vue'
import { useCandidateTestsStore } from '@/stores/candidateTests.store'

export function useTimer () {
  const timerCount = ref(0)
  const timeOver = ref(false)
  const counterOn = ref(false)

  const { updateTimer } = useCandidateTestsStore()

  const startCounter = (maxSeconds) => {
    timerCount.value = maxSeconds
    counterOn.value = true
    countDownTimer()
  }

  const stopCounter = () => {
    counterOn.value = false
  }

  const timerCountFormatted = computed(
    () => new Date(timerCount.value * 1000).toISOString().slice(14, 19)
  )

  const countDownTimer = () => {
    if (counterOn.value) {
      // Update store timer
      updateTimer(timerCountFormatted.value)
      // Decrease counter
      if (timerCount.value > 0) {
        setTimeout(() => {
          timerCount.value -= 1
          countDownTimer()
        }, 1000)
      } else {
        timeOver.value = true
      }
    }
  }

  return {
    startCounter,
    stopCounter,
    timeOver
  }
}
