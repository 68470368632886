<template>
  <div class="container px-0">
    <slot name="header"></slot>
    <div class="row">
      <div v-if="props.questionNumber" class="col-sm-1 mb-2">
        <b class="badge bg-primary">{{ props.questionNumber }}</b>
      </div>
      <div class="statement-box col-sm-10 fw-bold">
        {{ question.statement }}
      </div>
      <div class="row">
        <div :id="`text-container-form-${props.questionNumber}`" class="col-sm-12 offset-sm-1 mt-2">
          <!-- Example question -->
          <div v-if="props.isExample" class="">
            <ul v-if="props.expectedAnswers">
              <li v-for="expectedAnswer in props.expectedAnswers" :key="expectedAnswer">
                <input
                  type="number"
                  :placeholder="props.placeholder"
                  v-model="answers[question.id][expectedAnswer]"
                  :id="`example-question-${props.questionNumber}`"
                  class="form-control w-25 my-2">
              </li>
            </ul>
            <div v-else>
              <input
                type="number"
                :step="props.step"
                :placeholder="props.placeholder"
                v-model="answers[question.id]"
                :id="`question-${question.id}`"
                class="form-control w-25 my-2">
            </div>
          </div>
          <!-- Test question -->
          <div v-else class="">
            <ul v-if="props.expectedAnswers">
              <li v-for="expectedAnswer in props.expectedAnswers" class="col-lg-3 col-sm-10" :key="expectedAnswer">
                <input
                  type="number"
                  :step="props.step"
                  :placeholder="props.placeholder"
                  v-model="answers[question.id][expectedAnswer]"
                  :id="`question-${question.id}`"
                  class="form-control my-2">
              </li>
            </ul>
            <div v-else class="col-lg-4 col-sm-10">
              <input
                type="number"
                :step="props.step"
                :placeholder="props.placeholder"
                v-model="answers[question.id]"
                :id="`question-${question.id}`"
                class="form-control my-2">
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'

const props = defineProps({
  question: Object,
  questionNumber: Number,
  expectedAnswers: Number,
  placeholder: String,
  step: Number,
  answersForm: Object,
  isExample: Boolean
})

const answers = ref(props.answersForm)

</script>

<style scoped lang="scss">
  .statement-box {
    white-space:pre-wrap;
  }
</style>
