<template>
  <DialogsWrapper v-if="!loading"/>
  <Loading v-model:active="loading" loader="dots"/>
  <div>
    <div v-if="testViewData" class="container mt-5 bg-white shadow p-3">
      <!-- Instructions -->
      <div class="p-3 fw-bold">
        <div>
          {{ testViewData.instruction }}
        </div>
      </div>
      <!-- Warning -->
      <div v-if="warnings.blankOption" class="pt-3 ps-3">
        <p class="text-danger mb-0 py-1 fw-500">{{ $t('candidates.tests.mustChooseAnAlternative') }}</p>
      </div>
      <hr>
      <!-- Questions -->
      <div class="p-3 questions-container">
        <div v-for="(question, gIndex) in testViewData.questions" :key="gIndex">
          <TextAlternativeQuestion
            v-if="currentQuestion === gIndex + 1"
            :question="question"
            :gIndex="gIndex"
            :alternatives="[
              { text: $t('candidates.tests.agree'), value: 'A' },
              { text: $t('candidates.tests.disagree'), value: 'D' }
            ]"
            :currentQuestion="currentQuestion"
            :questionNumber="gIndex + 1"
            :answersForm="answersForm"
          />
        </div>
        <!-- End -->
        <div class="py-4 buttons-container">
          <button v-if="currentQuestion !== 1" @click="nextQuestion('back')" class="btn white-general-btn my-1 mx-2">{{ $t('candidates.tests.previous') }}</button>
          <button v-if="currentQuestion < maxQuestions" @click="nextQuestion('next')" class="btn white-general-btn my-1 mx-2">{{ $t('candidates.tests.next') }}</button>
          <button v-if="currentQuestion === maxQuestions" @click="sendAnswers(testAnswer.id, testAnswer.test_request_id)" class="btn general-btn my-1 mx-2">{{ $t('candidates.tests.sendAnswers') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useCandidateTestsStore } from '@/stores/candidateTests.store'
import TextAlternativeQuestion from '@/components/tests/TextAlternativeQuestion.vue'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import Loading from 'vue-loading-overlay'
import { useNavigationGuard } from '@/use/useNavigationGuard'
import 'vue-loading-overlay/dist/vue-loading.css'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { updateAnswer, toggleTestStatus, fetchTestData } = useCandidateTestsStore()
const { testViewData, testAnswer, loading, testInProgress } = storeToRefs(useCandidateTestsStore())
const route = useRoute()

const warnings = reactive({
  blankOption: false
})
const maxQuestions = 33
const currentQuestion = ref(1)
const answersForm = ref({})

const confirmSendAnswersDialog = createConfirmDialog(ConfirmationModal)

/*
  Get test questions form and create empty answer.
*/

onBeforeMount(() => {
  fetchTestData(+route.params.id)
})

/*
  Navigation guard
*/

useNavigationGuard(testInProgress, () => {
  const params = {
    test_answer: {
      answer: answersForm.value,
      test_request_id: testAnswer.value?.test_request_id
    }
  }
  toggleTestStatus(false)
  updateAnswer(testAnswer.value?.id, params, true)
})

/*
  Test in progress
*/

onMounted(() => {
  toggleTestStatus(true)
})

/*
  Questions logic
*/

const nextQuestion = (direction) => {
  switch (direction) {
    case 'next':
      if (validateQuestionInputs(currentQuestion.value)) {
        currentQuestion.value += 1
        warnings.blankOption = false
      } else {
        warnings.blankOption = true
      }
      break
    case 'back':
      currentQuestion.value -= 1
      warnings.blankOption = false
      break
  }
}

const validateQuestionInputs = (questionIndex) => {
  return answersForm.value[questionIndex] !== undefined
}

const validateAllInputs = () => {
  let valid = true
  for (let question = 1; question <= maxQuestions; question++) {
    const validQuestion = validateQuestionInputs(question)
    valid = valid && validQuestion
  }
  return valid
}

/*
  Send answers
*/

const sendAnswers = async (testAnswerId, testRequestId) => {
  const params = {
    test_answer: {
      answer: answersForm.value,
      test_request_id: testRequestId
    }
  }
  if (validateAllInputs()) {
    // Open confirmation modal
    const confirmation = await confirmSendAnswersDialog.reveal({
      icon: 'circle-info',
      title: t('candidates.tests.aboutToSendAnswers'),
      text: t('confirmAction'),
      confirmBtnText: t('candidates.tests.sendAnswers'),
      cancelBtnText: t('candidates.tests.backToTest')
    })
    // On confirm, change test status and update answer
    if (!confirmation.isCanceled) {
      toggleTestStatus(false)
      updateAnswer(testAnswerId, params, true)
    }
  }
}

</script>

<style scoped>
  @media (max-width: 1399px) {
    .questions-container {
      width: 100%;
    }
    .buttons-container {
      text-align: center;
    }
  }
  @media (min-width: 1400px) {
    .questions-container {
      width: 50%;
    }
    .buttons-container {
      text-align: end;
    }
  }
</style>
