import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth.store'
import { getUserTypePath } from '@/helpers/get-user-type-path'

// Candidates
import CandidatesLogin from '@/views/candidates/Login.vue'
import CandidatesTests from '@/views/candidates/Tests.vue'
import CandidatesTestDetail from '@/views/candidates/TestDetail.vue'
import CandidatesFinishedTest from '@/views/candidates/FinishedTest.vue'
import CandidatesPersonalInfo from '@/views/candidates/account/PersonalInfo.vue'
import CandidatesPassword from '@/views/candidates/account/Password.vue'
import CandidatesChangePassword from '@/views/candidates/account/ChangePassword.vue'
// Candidate tests
import ComplexInstructions from '@/views/candidates/tests/ComplexInstructions.vue'
import Excel from '@/views/candidates/tests/Excel.vue'
import Disc from '@/views/candidates/tests/Disc.vue'
import Prp from '@/views/candidates/tests/Prp.vue'
import Alert from '@/views/candidates/tests/Alert.vue'
import English from '@/views/candidates/tests/English.vue'
import Barratt from '@/views/candidates/tests/Barratt.vue'
import Pf16 from '@/views/candidates/tests/Pf16.vue'
import Wonderlic from '@/views/candidates/tests/Wonderlic.vue'
import Ceal from '@/views/candidates/tests/Ceal.vue'
import Psicomp from '@/views/candidates/tests/Psicomp.vue'

// Analysts
import AnalystsLogin from '@/views/analysts/Login.vue'
import AnalystsRequests from '@/views/analysts/Requests.vue'
import AnalystsTests from '@/views/analysts/Tests.vue'
import AnalystsCandidates from '@/views/analysts/Candidates.vue'
import AnalystsTestRequests from '@/views/analysts/TestRequests.vue'
import AnalystsCandidateRequests from '@/views/analysts/CandidateRequests.vue'
import AnalystsPersonalInfo from '@/views/analysts/account/PersonalInfo.vue'
import AnalystsPassword from '@/views/analysts/account/Password.vue'
import AnalystsChangePassword from '@/views/analysts/account/ChangePassword.vue'
import WrongRequest from '@/views/shared/WrongRequest.vue'

const routes = [
  {
    path: '/candidates',
    redirect: { name: 'candidatesTests' },
    children: [
      {
        path: 'login',
        name: 'candidatesLogin',
        component: CandidatesLogin
      },
      {
        path: 'tests',
        name: 'candidatesTests',
        component: CandidatesTests
      },
      {
        path: 'test-detail/:id',
        name: 'candidatesTestDetail',
        component: CandidatesTestDetail
      },
      {
        path: 'finished',
        name: 'candidatesFinishedTest',
        component: CandidatesFinishedTest
      },
      {
        path: 'account',
        redirect: { name: 'candidatesTests' },
        children: [
          {
            path: 'personal-info',
            name: 'candidatesPersonalInfo',
            component: CandidatesPersonalInfo
          },
          {
            path: 'password',
            name: 'candidatesPassword',
            component: CandidatesPassword
          },
          {
            path: 'change-password',
            name: 'candidatesChangePassword',
            component: CandidatesChangePassword
          }
        ]
      },
      {
        path: 'test',
        redirect: { name: 'candidatesTests' },
        children: [
          {
            path: '000/:id',
            name: 'candidatesComplexInstructions',
            component: ComplexInstructions
          },
          {
            path: '001/:id',
            name: 'candidatesExcel',
            component: Excel
          },
          {
            path: '002/:id',
            name: 'candidatesDisc',
            component: Disc
          },
          {
            path: '003/:id',
            name: 'candidatesPrp',
            component: Prp
          },
          {
            path: '004/:id',
            name: 'candidatesAlert',
            component: Alert
          },
          {
            path: '005/:id',
            name: 'candidatesEnglish',
            component: English
          },
          {
            path: '006/:id',
            name: 'candidatesBarratt',
            component: Barratt
          },
          {
            path: '007/:id',
            name: 'candidatesPf16',
            component: Pf16
          },
          {
            path: '008/:id',
            name: 'candidatesWonderlic',
            component: Wonderlic
          },
          {
            path: '009/:id',
            name: 'candidatesCeal',
            component: Ceal
          },
          {
            path: '010/:id',
            name: 'candidatesPsicomp',
            component: Psicomp
          }
        ]
      }
    ]
  },
  {
    path: '/analysts',
    redirect: { name: 'analystsRequests' },
    children: [
      {
        path: 'login',
        name: 'analystsLogin',
        component: AnalystsLogin
      },
      {
        path: 'requests',
        name: 'analystsRequests',
        component: AnalystsRequests
      },
      {
        path: 'tests',
        name: 'analystsTests',
        component: AnalystsTests
      },
      {
        path: 'test-requests/:id',
        name: 'analystsTestRequests',
        component: AnalystsTestRequests
      },
      {
        path: 'candidates',
        name: 'analystsCandidates',
        component: AnalystsCandidates
      },
      {
        path: 'candidate-requests/:id',
        name: 'analystsCandidateRequests',
        component: AnalystsCandidateRequests
      },
      {
        path: 'account',
        redirect: { name: 'analystsTests' },
        children: [
          {
            path: 'personal-info',
            name: 'analystsPersonalInfo',
            component: AnalystsPersonalInfo
          },
          {
            path: 'password',
            name: 'analystsPassword',
            component: AnalystsPassword
          },
          {
            path: 'change-password',
            name: 'analystsChangePassword',
            component: AnalystsChangePassword
          }
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'wrongRequest',
    component: WrongRequest
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()

  if (to.query.token && to.query.user_type) {
    const user = {
      credentials: { token: to.query.token },
      type: to.query.user_type
    }
    const language = to.query.lang || 'es'
    authStore.authorizeWithToken(user, language)
  }

  const userTypePath = getUserTypePath(authStore.userType)
  const toLogin = to.name === 'candidatesLogin' || to.name === 'analystsLogin'

  // Unlogged user can only go to login
  if (!authStore.user && !toLogin) {
    return { name: 'analystsLogin' }
  }
  // Logged user cannot go to login views
  if (authStore.user && authStore.userType && toLogin) {
    return { name: `${userTypePath}Tests` }
  }
  // Logged candidate with token can go to candidates tests-details
  const testId = to.query.id
  if (authStore.user && authStore.userType === 'candidate' && testId) {
    return { name: 'candidatesTestDetail', params: { id: testId } }
  }

  // Logged candidate only can go to candidates views
  const toCandidatesPath = to.name?.startsWith('candidates') || to.path.startsWith('/candidates')
  if (authStore.user && authStore.userType === 'candidate' && !toCandidatesPath) {
    return { name: 'candidatesTests' }
  }
  // Logged analyst can only go to analysts views
  const toAnalystsPath = to.name?.startsWith('analysts') || to.path.startsWith('/analysts')
  if (authStore.user && authStore.userType === 'analyst' && !toAnalystsPath) {
    return { name: 'analystsRequests' }
  }
})

export default router
