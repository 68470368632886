<template>
  <div class="container">
    <Loading v-model:active="loading" loader="dots" />
    <div class="shadow-lg p-5 mx-auto my-5 rounded-3 w-md-50">
      <div class="row">
        <div class="col-md-12">
          <h1>{{ $t('account.changePassword') }}</h1>
          <br />
          <h4>{{ $t('account.changeOrUpdatePassword') }}</h4>
        </div>
      </div>
      <PasswordForm :id="candidateId" :email="email" :updatePassword="updatePassword" />
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount } from 'vue'
import { useCandidateStore } from '@/stores/candidate.store'
import { storeToRefs } from 'pinia'
import PasswordForm from '@/components/PasswordForm.vue'
import Loading from 'vue-loading-overlay'

const { fetchPersonalInfo, updatePassword } = useCandidateStore()
const { email, candidateId, loading } = storeToRefs(useCandidateStore())

onBeforeMount(() => {
  fetchPersonalInfo()
})

</script>

<style lang="scss" scoped>
.w-md-50 {
  width: 50%;
}

@media (max-width: 768px) {
  .w-md-50 {
    width: 100%;
  }
}
</style>
