import moment from 'moment'

function dateAndTime (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
}

function onlyDate (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YY')
  }
}

export { dateAndTime, onlyDate }
