<template>
  <table class="table table-borderless">
    <thead>
      <tr>
        <th scope="col" class="col-sm-3"><p class="badge bg-primary">{{ gIndex + 1 }}</p></th>
        <th scope="col" class="text-center col-sm-2"><p>{{ $t('candidates.tests.disc.more') }}</p></th>
        <th scope="col" class="text-center col-sm-2"><p>{{ $t('candidates.tests.disc.less') }}</p></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, rIndex) in props.group" :key="rIndex">
        <td><b>{{ row }}</b></td>
        <td class="text-center">
          <input
            type="radio"
            :value="rIndex + 1"
            v-model="answersFormMost"
          >
        </td>
        <td class="text-center">
          <input
            type="radio"
            :value="rIndex + 1"
            v-model="answersFormLeast"
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue'

const props = defineProps({
  mostValue: Number,
  leastValue: Number,
  group: Array,
  gIndex: Number,
  currentGroup: Number,
  maxGroups: Number
})

const answersFormMost = ref(props.mostValue)
const answersFormLeast = ref(props.leastValue)

const emit = defineEmits(['updateAnswersFormMost', 'updateAnswersFormLeast'])

watch(() => answersFormMost.value, (newValue) => {
  emit('updateAnswersFormMost', 'most', props.gIndex + 1, newValue)
})
watch(() => answersFormLeast.value, (newValue) => {
  emit('updateAnswersFormLeast', 'least', props.gIndex + 1, newValue)
})
</script>
