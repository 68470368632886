<template>
  <Loading v-model:active="loading" loader="dots" />
  <div id="form" class="shadow p-3 mx-auto my-5 rounded-8 w-75 bg-white">
    <div class="accordion accordion-flush" id="titleAccordion">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            <h2 class="text-primary fw-500">{{ textAction }} {{ $t('analysts.requests.candidates') }}</h2>
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          :class="props.showAccordion ? 'show' : ''"
          aria-labelledby="headingOne"
          data-bs-parent="#titleAccordion"
        >
          <div class="accordion-body">
            <div class="row">
              <hr />

              <!-- One candidate -->
              <form class="col-sm-3 p-3" @submit.prevent="sendCandidateRequest">
                <h5 class="text-primary fw-500 mb-4">{{ textAction }} {{ $t('analysts.requests.byEmail') }}</h5>
                <div class="mb-4">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    v-model="formData.name"
                    :placeholder="$t('account.firstName')"
                  />
                </div>
                <div class="mb-4">
                  <input
                    type="text"
                    class="form-control"
                    id="lastname"
                    v-model="formData.lastname"
                    :placeholder="$t('account.lastName')"
                  />
                </div>
                <div class="mb-4">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    v-model="formData.email"
                    :placeholder="$t('login.email')"
                    required
                  />
                  <small class="text-secondary">*{{ $t('requiredField') }}</small>
                </div>
                <button type="submit" class="mt-4 ms-0 float-start btn general-btn">
                  {{ textAction }} {{ $t('analysts.requests.candidate').toLowerCase() }}
                </button>
              </form>

              <!-- Multiple candidates -->
              <form class="col-sm-7 p-3 mx-5" @submit.prevent="sendFileRequest">
                <h5 class="text-primary fw-500 mb-4">{{ $t('analysts.requests.uploadCandidatesList') }}</h5>
                <DragAndDropFile @drop.prevent="drop" @change="selectedFile" />
                <p v-if="dropzoneFile.name" class="mb-0 pt-2 text-secondary">
                  {{ $t('analysts.requests.file') }}: {{ dropzoneFile.name }}
                </p>
                <div v-if="fileWarning" class="mb-0 pt-2 text-danger fw-bold">
                  <small>*{{ fileWarning }}</small>
                </div>
                <div class="d-flex justify-content-start mt-4">
                  <button type="submit" class="btn general-btn">
                    {{ textAction }} {{ $t('analysts.requests.toAll') }}
                  </button>
                  <a
                    href="/files/BatchCandidateTemplate.xlsx"
                    download="template.xlsx"
                    class="btn white-general-btn"
                    >{{ $t('analysts.requests.downloadTemplate') }}</a
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps } from 'vue'
import DragAndDropFile from '@/components/DragAndDropFile.vue'
import { addCandidate, batchAddCandidates } from '@/services/analysts/candidates'
import { requestTest, batchInviteCandidates } from '@/services/analysts/test_requests'
import { createToast } from 'mosha-vue-toastify'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import { useAnalystTestsStore } from '@/stores/analystTests.store'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { fetchTestRequestsFromTest, fetchCandidates } = useAnalystTestsStore()

const props = defineProps({
  formAction: String,
  showAccordion: Boolean,
  testId: Number
})

const loading = ref(false)

/*
  Add/invite one candidate
*/
const textAction = props.formAction === 'add' ? t('analysts.candidates.add') : t('analysts.tests.invite')

const formData = reactive({
  name: '',
  lastname: '',
  email: ''
})

const sendCandidateRequest = async () => {
  loading.value = true
  if (props.formAction === 'add') {
    try {
      await addCandidate(formData)
      fetchCandidates()
      const message = t('analysts.candidates.candidateAddedSuccessfully')
      createToast(message, {
        hideProgressBar: true,
        type: 'success',
        showIcon: true,
        position: 'bottom-right'
      })
    } catch (e) {
      const message = t('analysts.candidates.errorAddingCandidate')
      createToast(message, {
        hideProgressBar: true,
        type: 'danger',
        showIcon: true,
        position: 'bottom-right'
      })
    }
  } else if (props.formAction === 'invite') {
    try {
      await requestTest(props.testId, formData)
      fetchTestRequestsFromTest(props.testId)
      const message = t('analysts.requests.candidateInvitedSuccessfully')
      createToast(message, {
        hideProgressBar: true,
        type: 'success',
        showIcon: true,
        position: 'bottom-right'
      })
    } catch (e) {
      const message = t('analysts.requests.errorWhileInviting')
      createToast(message, {
        hideProgressBar: true,
        type: 'danger',
        showIcon: true,
        position: 'bottom-right'
      })
    }
  }
  loading.value = false
}

/*
  Add/invite multiple candidates
*/

const dropzoneFile = ref('')
const fileWarning = ref('')

const drop = (event) => {
  fileWarning.value = ''
  dropzoneFile.value = event.dataTransfer.files[0]
}

const selectedFile = () => {
  dropzoneFile.value = document.querySelector('dropzoneFile').files[0]
}

const sendFileRequest = async () => {
  // Validate file uploaded
  if (!dropzoneFile.value) {
    fileWarning.value = t('analysts.requests.mustUploadFile')
    return
  }
  // Validate format
  const correctFormat =
    dropzoneFile.value.type ===
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  if (!correctFormat) {
    fileWarning.value = t('analysts.requests.fileMustBeExcel')
    return
  }
  // Api calls
  loading.value = true
  try {
    let message
    if (props.formAction === 'add') {
      await batchAddCandidates(dropzoneFile.value)
      fetchCandidates()
      message = t('analysts.candidates.candidatesAddedSuccessfully')
    } else if (props.formAction === 'invite') {
      await batchInviteCandidates(dropzoneFile.value, props.testId)
      fetchTestRequestsFromTest(props.testId)
      message = t('analysts.requests.candidatesInvitedSuccessfully')
    }
    createToast(message, {
      hideProgressBar: true,
      type: 'success',
      showIcon: true,
      position: 'bottom-right'
    })
  } catch (e) {
    const message = t('analysts.candidates.errorUploaddingData')
    createToast(message, {
      hideProgressBar: true,
      type: 'danger',
      showIcon: true,
      position: 'bottom-right'
    })
  }
  dropzoneFile.value = ''
  loading.value = false
}
</script>
