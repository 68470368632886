<template>
  <div class="m-5 p-2">
    <h2 class="text-primary">{{ $t('analysts.tests.yourTests') }}</h2>

    <div class="p-2">
      <Loading v-model:active="loading" loader="dots"/>
      <div v-if="!loading" class="row row-cols-auto row-wrapper">
        <div class="col my-3 px-0 me-4" v-for="test in tests" :key="test.id">
          <TestCard :test="test">
            <!-- Actions -->
            <template v-slot:actions>
              <div class="card-footer bg-transparent border-0 py-3">
                <RouterLink :to="`/analysts/test-requests/${test.id}`" class="btn general-btn">
                  {{ test.test_requests.length === 0 ? $t('analysts.tests.invite') : $t('analysts.tests.seeRequests') }}
                </RouterLink>
                <a :href="`/files/data_sheets/${getCurrentLocale()}/${test.data_sheet_file_name}`" download class="btn white-general-btn">
                  {{ $t('analysts.tests.dataSheet') }} <span class="mb-1"><font-awesome-icon icon="file-arrow-down" /></span>
                </a>
              </div>
            </template>
          </TestCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TestCard from '@/components/TestCard.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { storeToRefs } from 'pinia'
import { useAnalystTestsStore } from '@/stores/analystTests.store'

import { getCurrentLocale } from '@/i18n'

const { tests, loading } = storeToRefs(useAnalystTestsStore())
const { fetchTests } = useAnalystTestsStore()

fetchTests()

</script>

<style lang="scss" scoped>
  .general-btn {
  padding: 6px 16px;
  }

  .white-general-btn {
    padding: 6px 16px;
  }

  @media (max-width: 400px) {
    .row-wrapper {
      overflow-x: scroll;
    }
  }
</style>
