export default {
  candidates: {
    home: {
      title: 'Inicio',
      pendingRequests: 'Solicitudes pendientes',
      company: 'Empresa',
      requestDate: 'Fecha solicitud',
      inProcess: 'En proceso',
      goToDetail: 'Ir al detalle',
      noPendingRequests: 'No tienes solicitudes pendientes',
      completedRequests: 'Solicitudes completadas',
      answerDate: 'Fecha respuesta',
      noCompletedRequests: 'Aún no has completado ninguna solicitud'
    },

    tests: {
      abandonTest: 'Abandonar test',
      confirmAbandonTest: 'Si abandonas el test se considerará como respondido y no podrás continuar. ¿Deseas abandonar?',
      aboutToLeaveTest: 'Estás a punto de abandonar el test',
      abandon: 'Abandonar',
      backToTest: 'Volver al test',
      timeOver: 'Tiempo Límite Cumplido',
      youHaveReachedTimeLimit: 'Has cumplido el tiempo límite del test.',
      lastQuestionSubmitted: 'Se ha enviado hasta la última pregunta que respondiste.',
      finishedTest: 'Test Finalizado',
      youHaveFinishedTheTest: 'Has finalizado el test con éxito. Revisa si tienes más solicitudes pendientes.',
      backToHome: 'Volver al inicio',
      testNotFound: 'No se ha encontrado el test',
      environmentRecommendation: 'Te recomendamos contestar el test desde un computador y en un ambiente tranquilo sin distracciones.',
      requestingCompany: 'Empresa solicitante',
      testInstructions: 'Instrucciones del test',
      startTest: 'Iniciar test',
      // Common Test Views
      instructions: 'Instrucciones',
      example: 'Ejemplo',
      previous: 'Anterior',
      next: 'Siguiente',
      sendAnswers: 'Enviar respuestas',
      aboutToSendAnswers: 'Estás a punto de enviar tus respuestas',
      mustChooseAnAlternative: 'Debes elegir una opción para continuar',
      always: 'Siempre o casi siempre',
      often: 'A menudo',
      occasionally: 'Ocasionalmente',
      never: 'Raramente o nunca',
      startPartOne: 'Comenzar parte I',
      startPartTwo: 'Comenzar parte II',
      totallyAgree: 'Totalmente de acuerdo',
      agree: 'De acuerdo',
      neutral: 'Indeciso',
      disagree: 'En desacuerdo',
      totallyDisagree: 'Totalmente en desacuerdo',
      // Particular Test Views
      complexInstructions: {
        insuredAmount: 'Cantidad asegurada',
        insuranceClass: 'Clase de seguro',
        date: 'Fecha'
      },
      disc: {
        profileSystem: 'Sistema de perfil personal',
        blankOptionsWarning: 'Debes elegir una opción para MÁS y una opción para MENOS',
        sameOptionWarning: 'No puedes escoger la misma opción',
        more: 'MÁS',
        less: 'MENOS'
      },
      wonderlic: {
        answerHonestly: 'Responda con honestidad y según su criterio',
        exampleQuestion: 'Pregunta de ejemplo'
      }
    }
  }
}
