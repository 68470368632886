import router from '@/router'
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetch-wrapper'
import { createToast } from 'mosha-vue-toastify'

import i18n from '../i18n'

const { t } = i18n().global

const handleError = (message) => {
  createToast(message, {
    hideProgressBar: true,
    type: 'danger',
    showIcon: true,
    position: 'bottom-right'
  })
}

export const useCandidateTestsStore = defineStore({
  id: 'candidateTests',
  state: () => ({
    // initialize state properties
    pendingTests: [],
    answeredTests: [],
    testRequest: null,
    testAnswer: null,
    testViewData: null,
    error: null,
    loading: false,
    testInProgress: false,
    showTimer: false,
    timerCountFormatted: ''
  }),
  actions: {
    // This should be used from the test view
    toggleTestStatus (newValue) {
      this.testInProgress = newValue
    },
    // This should be used from the test view
    toggleTimer (newValue) {
      this.showTimer = newValue
    },
    // This should be used from timer composable
    // and test view to set to '' again
    updateTimer (timer) {
      this.timerCountFormatted = timer
    },

    // Get all candidate's requested tests.
    fetchTests () {
      this.pendingTests = []
      this.answeredTests = []
      this.error = null
      this.loading = true
      fetchWrapper.get(`${process.env.VUE_APP_API_BASE}/candidates/test_requests/`)
        .then((response) => {
          this.answeredTests = response.filter((testRequest) => testRequest.test.answered)
          this.pendingTests = response.filter((testRequest) => !testRequest.test.answered)
        }).catch((error) => {
          this.error = error
          const message = t('genericError')
          handleError(message)
        }).finally(() => {
          this.loading = false
        })
    },
    // Fetch test data (questions, instructions) and create empty answer.
    fetchTestData (id) {
      const params = {
        test_answer: {
          test_request_id: id,
          answer: {}
        }
      }
      this.error = null
      this.loading = true
      fetchWrapper.post(`${process.env.VUE_APP_API_BASE}/candidates/test_answers`, params)
        .then((response) => {
          this.testAnswer = response.test_answer
          this.testViewData = response.test_view_data
        }).catch((error) => {
          this.error = error
          this.testInProgress = false
          router.push('/')
          const message = t('genericError')
          handleError(message)
        }).finally(() => {
          this.loading = false
        })
    },
    // Update test answer.
    updateAnswer (answerId, params, redirectionFlag = true) {
      this.loading = true
      this.error = null
      fetchWrapper.put(`${process.env.VUE_APP_API_BASE}/candidates/test_answers/${answerId}`, params)
        .then((response) => {
          // Redirect to finished test success view if flag
          if (redirectionFlag) {
            router.push({ name: 'candidatesFinishedTest' })
          }
        }).catch((error) => {
          this.error = error
          const message = t('genericError')
          handleError(message)
        }).finally(() => {
          this.testViewData = null
          this.testAnswer = null
          this.loading = false
        })
    },
    // Get test data by id from state properties
    getRequestById (id) {
      this.testRequest = this.pendingTests.filter((request) => request.id === id)[0]

      if (!this.testRequest) {
        // If no testRequest is present, fetch it from API.
        this.loading = true
        fetchWrapper.get(`${process.env.VUE_APP_API_BASE}/candidates/test_requests/${id}`)
          .then((response) => {
            this.testRequest = response
          }).catch((error) => {
            this.error = error
            const message = t('genericError')
            handleError(message)
          }).finally(() => {
            this.loading = false
          })
      }
    }
  }
})
