// This is a method to filter a list of objects by the text of some attributes

export function objectsFilter (elements, attributesToFilter, inputValue, nested = false, nestedAttr = '') {
  if (inputValue === '') {
    return elements
  }

  const filtered = new Set()

  attributesToFilter.forEach(attribute => {
    elements.forEach(element => {
      let filterable = null
      if (nested) {
        filterable = element[nestedAttr][attribute]
      } else {
        filterable = element[attribute]
      }
      if (filterable && filterable.toLowerCase().includes(inputValue.toLowerCase())) {
        filtered.add(element)
      }
    })
  })
  return Array.from(filtered)
}
