<template>
  <div>
    <div class="container text-sm-start">
      <Loading v-model:active="loading" :is-full-page="true" loader="dots"/>

      <div v-if="!testRequest" class="row">
        <div class="col-sm-12 text-center">
          <br>
          <h2 class="text-primary">{{ $t('candidates.tests.testNotFound') }}</h2>
        </div>
      </div>

      <div v-else class="row shadow bg-white test-detail-wrapper mt-3">
        <!-- Warning -->
         <div class="warning p-3 mb-5">
          {{ $t('candidates.tests.environmentRecommendation') }}
        </div>
        <!-- Request info -->
        <div class="row text-secondary text-start mb-4">
          <div class="col-sm-auto">
            <small class="me-2"><font-awesome-icon icon="building" /></small>
            <small class="me-1">{{ $t('candidates.tests.requestingCompany') }}:</small>
            <small class="fw-bold">{{ testRequest.company.name }}</small>
          </div>
          <div class="col-sm-auto">
            <small class="me-2"><font-awesome-icon icon="calendar" /></small>
            <small class="me-1">{{ $t('candidates.home.requestDate') }}:</small>
            <small class="fw-bold">{{ onlyDate(testRequest.sent_at) }}</small>
          </div>
        </div>
        <!-- Instructions -->
        <div class="col-sm-12">
          <h4 class="text-primary">{{ $t('candidates.tests.testInstructions') }}:</h4>
          <ol class="ps-3 instructions">
            <li v-for="(instruction, index) in testRequest.test.detail_view_data.instructions" class="mb-2" :key="index">
              {{ instruction }}
            </li>
          </ol>
          <br/>
          <!-- Action buttons -->
          <div class="d-flex justify-content-end">
            <button class="btn white-general-btn px-5" @click="$router.push('/candidates/tests')">{{ $t('back') }}</button>
            <RouterLink :to="{name: testTypeComponent(testRequest.test.type), params: { id: testRequestId }}" class="btn general-btn px-5">{{ $t('candidates.tests.startTest') }}</RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useCandidateTestsStore } from '@/stores/candidateTests.store'
import { onlyDate } from '@/helpers/format-date'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

const { testRequest, loading } = storeToRefs(useCandidateTestsStore())
const { getRequestById } = useCandidateTestsStore()
const route = useRoute()
const testRequestId = +route.params.id

// Find test from candidates store state properties.
getRequestById(testRequestId)

// Return test form component name depending on test type.
const testTypeComponent = (type) => {
  switch (type) {
    case 'Tests::ComplexInstructions':
      return 'candidatesComplexInstructions'
    case 'Tests::Excel':
      return 'candidatesExcel'
    case 'Tests::Disc':
      return 'candidatesDisc'
    case 'Tests::Prp':
      return 'candidatesPrp'
    case 'Tests::Alert':
      return 'candidatesAlert'
    case 'Tests::English':
      return 'candidatesEnglish'
    case 'Tests::Barratt':
      return 'candidatesBarratt'
    case 'Tests::Pf16':
      return 'candidatesPf16'
    case 'Tests::Wonderlic':
      return 'candidatesWonderlic'
    case 'Tests::Ceal':
      return 'candidatesCeal'
    case 'Tests::Psicomp':
      return 'candidatesPsicomp'
  }
}
</script>

<style scoped>
  .instructions {
    text-align: justify;
  }
  .warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    text-align: justify;
  }

  @media (max-width: 767.98px) {
    .test-detail-wrapper {
      padding: 1rem;
    }
  }
  @media (min-width: 768px) {
    .test-detail-wrapper {
      padding: 3.5rem;
    }
  }
</style>
